/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import { Provider, TransactionRequest } from "@ethersproject/providers";
import type { MixinUniswap, MixinUniswapInterface } from "../MixinUniswap";

const _abi = [
  {
    inputs: [
      {
        internalType: "contract IEtherTokenV06",
        name: "weth",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
];

const _bytecode =
  "0x60a060405234801561001057600080fd5b5060405161018d38038061018d83398181016040528101906100329190610108565b6100647f98e40373e1c649e136c4d039f3f2349d2168491de6d2959f8a8a4f29df88acfa60001b61010560201b60201c565b6100967f800ed28cfa394b52db1f11058449e689f64d7abe615f5040c1408c4d6550e1f560001b61010560201b60201c565b6100c87ff3854767cc7634e5e4502d6876f1418fb6b1f1b4f70559b382e40d4588b6fd9260001b61010560201b60201c565b8073ffffffffffffffffffffffffffffffffffffffff1660808173ffffffffffffffffffffffffffffffffffffffff1660601b8152505050610138565b50565b600060208284031215610119578081fd5b815160018060a01b038116811461012e578182fd5b8091505092915050565b60805160601c603f61014e60003950603f6000f3fe6080604052600080fdfea2646970667358221220a3983fa20867958f42186880b2ead132b596504d96c9e4c64e1f8efbb2b506b964736f6c634300060c0033";

export class MixinUniswap__factory extends ContractFactory {
  constructor(
    ...args: [signer: Signer] | ConstructorParameters<typeof ContractFactory>
  ) {
    if (args.length === 1) {
      super(_abi, _bytecode, args[0]);
    } else {
      super(...args);
    }
  }

  deploy(
    weth: string,
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<MixinUniswap> {
    return super.deploy(weth, overrides || {}) as Promise<MixinUniswap>;
  }
  getDeployTransaction(
    weth: string,
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(weth, overrides || {});
  }
  attach(address: string): MixinUniswap {
    return super.attach(address) as MixinUniswap;
  }
  connect(signer: Signer): MixinUniswap__factory {
    return super.connect(signer) as MixinUniswap__factory;
  }
  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): MixinUniswapInterface {
    return new utils.Interface(_abi) as MixinUniswapInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): MixinUniswap {
    return new Contract(address, _abi, signerOrProvider) as MixinUniswap;
  }
}
