/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type { ILido, ILidoInterface } from "../ILido";

const _abi = [
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_sharesAmount",
        type: "uint256",
      },
    ],
    name: "getPooledEthByShares",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_referral",
        type: "address",
      },
    ],
    name: "submit",
    outputs: [
      {
        internalType: "uint256",
        name: "StETH",
        type: "uint256",
      },
    ],
    stateMutability: "payable",
    type: "function",
  },
];

export class ILido__factory {
  static readonly abi = _abi;
  static createInterface(): ILidoInterface {
    return new utils.Interface(_abi) as ILidoInterface;
  }
  static connect(address: string, signerOrProvider: Signer | Provider): ILido {
    return new Contract(address, _abi, signerOrProvider) as ILido;
  }
}
