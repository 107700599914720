import { ethers } from "ethers";
import { IZeroEx__factory, MockERC1155__factory, MockERC20__factory, MockERC721__factory, MockWETH__factory, Multicall2__factory } from "./typechain";
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import { selectProvider } from "./dexSlice";
import styles from './Assets.module.css';
import { getERC20Address, getWETHAddress, getZEROEXAddress } from "./contracts";
import { getUniqKey } from "./utils";
import { b } from "./dex-utils";

export function Assets(_props: any) {
  const utils = ethers.utils;
  const [assets, setAssets] = useState<Array<string>>([]);

  const provider = useAppSelector(selectProvider);
  const signer = provider.getSigner();
  const erc20 = MockERC20__factory.connect(getERC20Address(), provider);
  const karafuru = MockERC721__factory.connect("0x9183c3D7be1A6333A2948f70e63214934B90cFcD", provider);
  const azuki = MockERC721__factory.connect("0x9B90f643dA4bC61386B431Cd0877acA9f834284d", provider);
  const opendao = MockERC1155__factory.connect("0xfbDcF4A63A910B720f8cfe9390E9543B0D3d9275", provider);
  const multicall = Multicall2__factory.connect("0x5ba1e12693dc8f9c48aad8770482f4739beed696", provider);
  const WETH = MockWETH__factory.connect(getWETHAddress(), provider);
  const dex = IZeroEx__factory.connect(getZEROEXAddress(), provider);

  async function getAssets() {
    const address = await signer.getAddress();
    const queries: Array<any> = [];
    for (const account of [signer]) {
      queries.push(Promise.all([
        address,
        account.getBalance(),
        erc20.balanceOf(address),
        karafuru.balanceOf(address),
        azuki.balanceOf(address),
        Promise.all([
          opendao.balanceOf(address, 0),
          opendao.balanceOf(address, 1),
          opendao.balanceOf(address, 2),
        ]),
        WETH.balanceOf(address),
      ]));
    }
    for (let j = 0; j < queries.length; ++j) {
      const [addr, eth, _erc20, _karafuru, _azuki, _opendao, _weth] = await queries[j];
      const x: Array<any> = [addr, eth, _erc20, _karafuru, _azuki, _opendao, _weth];

      const karafuruTokens = [];
      for (let i = 0; i < _karafuru.toNumber(); ++i) {
        karafuruTokens.push(karafuru.tokenOfOwnerByIndex(addr, i))
      }
      x.push(Promise.all(karafuruTokens));

      const azukiTokens = [];
      for (let i = 0; i < _azuki.toNumber(); ++i) {
        azukiTokens.push(azuki.tokenOfOwnerByIndex(addr, i))
      }
      x.push(Promise.all(azukiTokens));

      queries[j] = Promise.all(x);
    }

    for (const q of queries) {
      const [addr, eth, _erc20, _karafuru, _azuki, _opendao, _weth, karafuruTokens, azukiTokens] = await q;
      setAssets([
        `${addr} ---- ${utils.formatEther(eth)} ---- ether`,
        `${WETH.address} ---- ${utils.formatEther(_weth)} ---- WETH`,
        `${erc20.address} ---- ${utils.formatEther(_erc20)} ---- ERC20`,
        `${karafuru.address} ---- ${_karafuru} ---- Karafuru [${karafuruTokens.map((i: any) => i.toNumber())}]`,
        `${azuki.address} ---- ${_azuki} ---- Azuki [${azukiTokens.map((i: any) => i.toNumber())}]`,
        `${opendao.address} ---- ${_opendao[0]} ---- Opendao[tokenID=0]`,
        `${opendao.address} ---- ${_opendao[1]} ---- Opendao[tokenID=1]`,
        `${opendao.address} ---- ${_opendao[2]} ---- Opendao[tokenID=2]`,
      ]);
      break;
    }
  }
  async function mintOpendao() {
    const addr = await signer.getAddress();
    await multicall.connect(signer).aggregate([
      { target: opendao.address, callData: (await opendao.populateTransaction.mintTo(addr, 0, 2)).data || "0x" },
      { target: opendao.address, callData: (await opendao.populateTransaction.mintTo(addr, 1, 2)).data || "0x" },
      { target: opendao.address, callData: (await opendao.populateTransaction.mintTo(addr, 2, 2)).data || "0x" },
    ]);
  }
  async function mintKarafuru() {
    const addr = await signer.getAddress();
    const gasLimit = await karafuru.estimateGas.mintTo(addr, 2);
    await karafuru.connect(signer).mintTo(addr, 2, {
      gasLimit: gasLimit.mul(3),
    });
  }
  async function mintAzuki() {
    const addr = await signer.getAddress();
    const gasLimit = await azuki.estimateGas.mintTo(addr, 2);
    await azuki.connect(signer).mintTo(addr, 2, {
      gasLimit: gasLimit.mul(3),
    });
  }
  async function mintERC20() {
    const addr = await signer.getAddress();
    await erc20.connect(signer).mintTo(addr, b("10"));
  }

  async function revokeAll() {
    await WETH.connect(signer).approve(dex.address, 0);
    await erc20.connect(signer).approve(dex.address, 0);
    await opendao.connect(signer).setApprovalForAll(dex.address, false);
    await azuki.connect(signer).setApprovalForAll(dex.address, false);
    await karafuru.connect(signer).setApprovalForAll(dex.address, false);
  }

  async function depositWETH() {
    await WETH.connect(signer).deposit({
      value: b("0.1"),
    });
  }
  async function withdrawWETH() {
    const balance = await WETH.balanceOf(await signer.getAddress());
    await WETH.connect(signer).withdraw(balance);
  }

  return (
    <div className={styles.border}>
      <h2>Assets</h2>
      <button onClick={getAssets}>Show Assets</button><br></br>
      <button onClick={mintERC20}>Mint ERC20 Test Coin</button>
      <button onClick={depositWETH}>deposit WETH</button>
      <button onClick={withdrawWETH}>withdraw WETH</button><br></br>

      <button onClick={mintOpendao}>Mint Opendao(ERC1155)</button>
      <button onClick={mintKarafuru}>mint Karafuru(ERC721)</button>
      <button onClick={mintAzuki}>mint Azuki(ERC721)</button><br></br>

      <button onClick={revokeAll}>revoke All</button><br></br>

      {assets.map((i) => {
        return (
          <div key={getUniqKey()}>
            {i}
            <br></br>
          </div>
        );
      })}
    </div>
  );
}
