/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type {
  IERC1155OrdersFeature,
  IERC1155OrdersFeatureInterface,
} from "../IERC1155OrdersFeature";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "maker",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "nonce",
        type: "uint256",
      },
    ],
    name: "ERC1155OrderCancelled",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "enum LibNFTOrder.TradeDirection",
        name: "direction",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "address",
        name: "maker",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "taker",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "nonce",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "contract IERC20TokenV06",
        name: "erc20Token",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "erc20FillAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "contract IERC1155Token",
        name: "erc1155Token",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "erc1155TokenId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint128",
        name: "erc1155FillAmount",
        type: "uint128",
      },
      {
        indexed: false,
        internalType: "address",
        name: "matcher",
        type: "address",
      },
    ],
    name: "ERC1155OrderFilled",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "enum LibNFTOrder.TradeDirection",
        name: "direction",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "address",
        name: "maker",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "taker",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "expiry",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "nonce",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "contract IERC20TokenV06",
        name: "erc20Token",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "erc20TokenAmount",
        type: "uint256",
      },
      {
        components: [
          {
            internalType: "address",
            name: "recipient",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
          {
            internalType: "bytes",
            name: "feeData",
            type: "bytes",
          },
        ],
        indexed: false,
        internalType: "struct LibNFTOrder.Fee[]",
        name: "fees",
        type: "tuple[]",
      },
      {
        indexed: false,
        internalType: "contract IERC1155Token",
        name: "erc1155Token",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "erc1155TokenId",
        type: "uint256",
      },
      {
        components: [
          {
            internalType: "contract IPropertyValidator",
            name: "propertyValidator",
            type: "address",
          },
          {
            internalType: "bytes",
            name: "propertyData",
            type: "bytes",
          },
        ],
        indexed: false,
        internalType: "struct LibNFTOrder.Property[]",
        name: "erc1155TokenProperties",
        type: "tuple[]",
      },
      {
        indexed: false,
        internalType: "uint128",
        name: "erc1155TokenAmount",
        type: "uint128",
      },
    ],
    name: "ERC1155OrderPreSigned",
    type: "event",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "enum LibNFTOrder.TradeDirection",
            name: "direction",
            type: "uint8",
          },
          {
            internalType: "address",
            name: "maker",
            type: "address",
          },
          {
            internalType: "address",
            name: "taker",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "expiry",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "nonce",
            type: "uint256",
          },
          {
            internalType: "contract IERC20TokenV06",
            name: "erc20Token",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "erc20TokenAmount",
            type: "uint256",
          },
          {
            components: [
              {
                internalType: "address",
                name: "recipient",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
              },
              {
                internalType: "bytes",
                name: "feeData",
                type: "bytes",
              },
            ],
            internalType: "struct LibNFTOrder.Fee[]",
            name: "fees",
            type: "tuple[]",
          },
          {
            internalType: "contract IERC1155Token",
            name: "erc1155Token",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "erc1155TokenId",
            type: "uint256",
          },
          {
            components: [
              {
                internalType: "contract IPropertyValidator",
                name: "propertyValidator",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "propertyData",
                type: "bytes",
              },
            ],
            internalType: "struct LibNFTOrder.Property[]",
            name: "erc1155TokenProperties",
            type: "tuple[]",
          },
          {
            internalType: "uint128",
            name: "erc1155TokenAmount",
            type: "uint128",
          },
        ],
        internalType: "struct LibNFTOrder.ERC1155Order[]",
        name: "sellOrders",
        type: "tuple[]",
      },
      {
        components: [
          {
            internalType: "enum LibSignature.SignatureType",
            name: "signatureType",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "v",
            type: "uint8",
          },
          {
            internalType: "bytes32",
            name: "r",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "s",
            type: "bytes32",
          },
        ],
        internalType: "struct LibSignature.Signature[]",
        name: "signatures",
        type: "tuple[]",
      },
      {
        internalType: "uint128[]",
        name: "erc1155TokenAmounts",
        type: "uint128[]",
      },
      {
        internalType: "bytes[]",
        name: "callbackData",
        type: "bytes[]",
      },
      {
        internalType: "bool",
        name: "revertIfIncomplete",
        type: "bool",
      },
    ],
    name: "batchBuyERC1155s",
    outputs: [
      {
        internalType: "bool[]",
        name: "successes",
        type: "bool[]",
      },
    ],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "enum LibNFTOrder.TradeDirection",
            name: "direction",
            type: "uint8",
          },
          {
            internalType: "address",
            name: "maker",
            type: "address",
          },
          {
            internalType: "address",
            name: "taker",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "expiry",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "nonce",
            type: "uint256",
          },
          {
            internalType: "contract IERC20TokenV06",
            name: "erc20Token",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "erc20TokenAmount",
            type: "uint256",
          },
          {
            components: [
              {
                internalType: "address",
                name: "recipient",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
              },
              {
                internalType: "bytes",
                name: "feeData",
                type: "bytes",
              },
            ],
            internalType: "struct LibNFTOrder.Fee[]",
            name: "fees",
            type: "tuple[]",
          },
          {
            internalType: "contract IERC1155Token",
            name: "erc1155Token",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "erc1155TokenId",
            type: "uint256",
          },
          {
            components: [
              {
                internalType: "contract IPropertyValidator",
                name: "propertyValidator",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "propertyData",
                type: "bytes",
              },
            ],
            internalType: "struct LibNFTOrder.Property[]",
            name: "erc1155TokenProperties",
            type: "tuple[]",
          },
          {
            internalType: "uint128",
            name: "erc1155TokenAmount",
            type: "uint128",
          },
        ],
        internalType: "struct LibNFTOrder.ERC1155Order[]",
        name: "sellOrders",
        type: "tuple[]",
      },
      {
        components: [
          {
            internalType: "enum LibSignature.SignatureType",
            name: "signatureType",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "v",
            type: "uint8",
          },
          {
            internalType: "bytes32",
            name: "r",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "s",
            type: "bytes32",
          },
        ],
        internalType: "struct LibSignature.Signature[]",
        name: "signatures",
        type: "tuple[]",
      },
      {
        internalType: "uint128[]",
        name: "erc1155FillAmounts",
        type: "uint128[]",
      },
      {
        internalType: "bytes[]",
        name: "callbackData",
        type: "bytes[]",
      },
      {
        internalType: "bool",
        name: "revertIfIncomplete",
        type: "bool",
      },
      {
        internalType: "uint256",
        name: "maxNFTs",
        type: "uint256",
      },
    ],
    name: "batchBuyERC1155sWithMaxNumber",
    outputs: [
      {
        internalType: "bool[]",
        name: "successes",
        type: "bool[]",
      },
    ],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256[]",
        name: "orderNonces",
        type: "uint256[]",
      },
    ],
    name: "batchCancelERC1155Orders",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "enum LibNFTOrder.TradeDirection",
            name: "direction",
            type: "uint8",
          },
          {
            internalType: "address",
            name: "maker",
            type: "address",
          },
          {
            internalType: "address",
            name: "taker",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "expiry",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "nonce",
            type: "uint256",
          },
          {
            internalType: "contract IERC20TokenV06",
            name: "erc20Token",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "erc20TokenAmount",
            type: "uint256",
          },
          {
            components: [
              {
                internalType: "address",
                name: "recipient",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
              },
              {
                internalType: "bytes",
                name: "feeData",
                type: "bytes",
              },
            ],
            internalType: "struct LibNFTOrder.Fee[]",
            name: "fees",
            type: "tuple[]",
          },
          {
            internalType: "contract IERC1155Token",
            name: "erc1155Token",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "erc1155TokenId",
            type: "uint256",
          },
          {
            components: [
              {
                internalType: "contract IPropertyValidator",
                name: "propertyValidator",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "propertyData",
                type: "bytes",
              },
            ],
            internalType: "struct LibNFTOrder.Property[]",
            name: "erc1155TokenProperties",
            type: "tuple[]",
          },
          {
            internalType: "uint128",
            name: "erc1155TokenAmount",
            type: "uint128",
          },
        ],
        internalType: "struct LibNFTOrder.ERC1155Order",
        name: "sellOrder",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "enum LibSignature.SignatureType",
            name: "signatureType",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "v",
            type: "uint8",
          },
          {
            internalType: "bytes32",
            name: "r",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "s",
            type: "bytes32",
          },
        ],
        internalType: "struct LibSignature.Signature",
        name: "signature",
        type: "tuple",
      },
      {
        internalType: "uint128",
        name: "erc1155BuyAmount",
        type: "uint128",
      },
      {
        internalType: "bytes",
        name: "callbackData",
        type: "bytes",
      },
    ],
    name: "buyERC1155",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "orderNonce",
        type: "uint256",
      },
    ],
    name: "cancelERC1155Order",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "enum LibNFTOrder.TradeDirection",
            name: "direction",
            type: "uint8",
          },
          {
            internalType: "address",
            name: "maker",
            type: "address",
          },
          {
            internalType: "address",
            name: "taker",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "expiry",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "nonce",
            type: "uint256",
          },
          {
            internalType: "contract IERC20TokenV06",
            name: "erc20Token",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "erc20TokenAmount",
            type: "uint256",
          },
          {
            components: [
              {
                internalType: "address",
                name: "recipient",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
              },
              {
                internalType: "bytes",
                name: "feeData",
                type: "bytes",
              },
            ],
            internalType: "struct LibNFTOrder.Fee[]",
            name: "fees",
            type: "tuple[]",
          },
          {
            internalType: "contract IERC1155Token",
            name: "erc1155Token",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "erc1155TokenId",
            type: "uint256",
          },
          {
            components: [
              {
                internalType: "contract IPropertyValidator",
                name: "propertyValidator",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "propertyData",
                type: "bytes",
              },
            ],
            internalType: "struct LibNFTOrder.Property[]",
            name: "erc1155TokenProperties",
            type: "tuple[]",
          },
          {
            internalType: "uint128",
            name: "erc1155TokenAmount",
            type: "uint128",
          },
        ],
        internalType: "struct LibNFTOrder.ERC1155Order",
        name: "order",
        type: "tuple",
      },
    ],
    name: "getERC1155OrderHash",
    outputs: [
      {
        internalType: "bytes32",
        name: "orderHash",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "enum LibNFTOrder.TradeDirection",
            name: "direction",
            type: "uint8",
          },
          {
            internalType: "address",
            name: "maker",
            type: "address",
          },
          {
            internalType: "address",
            name: "taker",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "expiry",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "nonce",
            type: "uint256",
          },
          {
            internalType: "contract IERC20TokenV06",
            name: "erc20Token",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "erc20TokenAmount",
            type: "uint256",
          },
          {
            components: [
              {
                internalType: "address",
                name: "recipient",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
              },
              {
                internalType: "bytes",
                name: "feeData",
                type: "bytes",
              },
            ],
            internalType: "struct LibNFTOrder.Fee[]",
            name: "fees",
            type: "tuple[]",
          },
          {
            internalType: "contract IERC1155Token",
            name: "erc1155Token",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "erc1155TokenId",
            type: "uint256",
          },
          {
            components: [
              {
                internalType: "contract IPropertyValidator",
                name: "propertyValidator",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "propertyData",
                type: "bytes",
              },
            ],
            internalType: "struct LibNFTOrder.Property[]",
            name: "erc1155TokenProperties",
            type: "tuple[]",
          },
          {
            internalType: "uint128",
            name: "erc1155TokenAmount",
            type: "uint128",
          },
        ],
        internalType: "struct LibNFTOrder.ERC1155Order",
        name: "order",
        type: "tuple",
      },
    ],
    name: "getERC1155OrderInfo",
    outputs: [
      {
        components: [
          {
            internalType: "bytes32",
            name: "orderHash",
            type: "bytes32",
          },
          {
            internalType: "enum LibNFTOrder.OrderStatus",
            name: "status",
            type: "uint8",
          },
          {
            internalType: "uint128",
            name: "orderAmount",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "remainingAmount",
            type: "uint128",
          },
        ],
        internalType: "struct LibNFTOrder.OrderInfo",
        name: "orderInfo",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "operator",
        type: "address",
      },
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
      {
        internalType: "bytes",
        name: "data",
        type: "bytes",
      },
    ],
    name: "onERC1155Received",
    outputs: [
      {
        internalType: "bytes4",
        name: "success",
        type: "bytes4",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "enum LibNFTOrder.TradeDirection",
            name: "direction",
            type: "uint8",
          },
          {
            internalType: "address",
            name: "maker",
            type: "address",
          },
          {
            internalType: "address",
            name: "taker",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "expiry",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "nonce",
            type: "uint256",
          },
          {
            internalType: "contract IERC20TokenV06",
            name: "erc20Token",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "erc20TokenAmount",
            type: "uint256",
          },
          {
            components: [
              {
                internalType: "address",
                name: "recipient",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
              },
              {
                internalType: "bytes",
                name: "feeData",
                type: "bytes",
              },
            ],
            internalType: "struct LibNFTOrder.Fee[]",
            name: "fees",
            type: "tuple[]",
          },
          {
            internalType: "contract IERC1155Token",
            name: "erc1155Token",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "erc1155TokenId",
            type: "uint256",
          },
          {
            components: [
              {
                internalType: "contract IPropertyValidator",
                name: "propertyValidator",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "propertyData",
                type: "bytes",
              },
            ],
            internalType: "struct LibNFTOrder.Property[]",
            name: "erc1155TokenProperties",
            type: "tuple[]",
          },
          {
            internalType: "uint128",
            name: "erc1155TokenAmount",
            type: "uint128",
          },
        ],
        internalType: "struct LibNFTOrder.ERC1155Order",
        name: "order",
        type: "tuple",
      },
    ],
    name: "preSignERC1155Order",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "enum LibNFTOrder.TradeDirection",
            name: "direction",
            type: "uint8",
          },
          {
            internalType: "address",
            name: "maker",
            type: "address",
          },
          {
            internalType: "address",
            name: "taker",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "expiry",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "nonce",
            type: "uint256",
          },
          {
            internalType: "contract IERC20TokenV06",
            name: "erc20Token",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "erc20TokenAmount",
            type: "uint256",
          },
          {
            components: [
              {
                internalType: "address",
                name: "recipient",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
              },
              {
                internalType: "bytes",
                name: "feeData",
                type: "bytes",
              },
            ],
            internalType: "struct LibNFTOrder.Fee[]",
            name: "fees",
            type: "tuple[]",
          },
          {
            internalType: "contract IERC1155Token",
            name: "erc1155Token",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "erc1155TokenId",
            type: "uint256",
          },
          {
            components: [
              {
                internalType: "contract IPropertyValidator",
                name: "propertyValidator",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "propertyData",
                type: "bytes",
              },
            ],
            internalType: "struct LibNFTOrder.Property[]",
            name: "erc1155TokenProperties",
            type: "tuple[]",
          },
          {
            internalType: "uint128",
            name: "erc1155TokenAmount",
            type: "uint128",
          },
        ],
        internalType: "struct LibNFTOrder.ERC1155Order",
        name: "buyOrder",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "enum LibSignature.SignatureType",
            name: "signatureType",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "v",
            type: "uint8",
          },
          {
            internalType: "bytes32",
            name: "r",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "s",
            type: "bytes32",
          },
        ],
        internalType: "struct LibSignature.Signature",
        name: "signature",
        type: "tuple",
      },
      {
        internalType: "uint256",
        name: "erc1155TokenId",
        type: "uint256",
      },
      {
        internalType: "uint128",
        name: "erc1155SellAmount",
        type: "uint128",
      },
      {
        internalType: "bool",
        name: "unwrapNativeToken",
        type: "bool",
      },
      {
        internalType: "bytes",
        name: "callbackData",
        type: "bytes",
      },
    ],
    name: "sellERC1155",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "enum LibNFTOrder.TradeDirection",
            name: "direction",
            type: "uint8",
          },
          {
            internalType: "address",
            name: "maker",
            type: "address",
          },
          {
            internalType: "address",
            name: "taker",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "expiry",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "nonce",
            type: "uint256",
          },
          {
            internalType: "contract IERC20TokenV06",
            name: "erc20Token",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "erc20TokenAmount",
            type: "uint256",
          },
          {
            components: [
              {
                internalType: "address",
                name: "recipient",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
              },
              {
                internalType: "bytes",
                name: "feeData",
                type: "bytes",
              },
            ],
            internalType: "struct LibNFTOrder.Fee[]",
            name: "fees",
            type: "tuple[]",
          },
          {
            internalType: "contract IERC1155Token",
            name: "erc1155Token",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "erc1155TokenId",
            type: "uint256",
          },
          {
            components: [
              {
                internalType: "contract IPropertyValidator",
                name: "propertyValidator",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "propertyData",
                type: "bytes",
              },
            ],
            internalType: "struct LibNFTOrder.Property[]",
            name: "erc1155TokenProperties",
            type: "tuple[]",
          },
          {
            internalType: "uint128",
            name: "erc1155TokenAmount",
            type: "uint128",
          },
        ],
        internalType: "struct LibNFTOrder.ERC1155Order",
        name: "order",
        type: "tuple",
      },
      {
        internalType: "uint256",
        name: "erc1155TokenId",
        type: "uint256",
      },
    ],
    name: "validateERC1155OrderProperties",
    outputs: [],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "enum LibNFTOrder.TradeDirection",
            name: "direction",
            type: "uint8",
          },
          {
            internalType: "address",
            name: "maker",
            type: "address",
          },
          {
            internalType: "address",
            name: "taker",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "expiry",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "nonce",
            type: "uint256",
          },
          {
            internalType: "contract IERC20TokenV06",
            name: "erc20Token",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "erc20TokenAmount",
            type: "uint256",
          },
          {
            components: [
              {
                internalType: "address",
                name: "recipient",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "amount",
                type: "uint256",
              },
              {
                internalType: "bytes",
                name: "feeData",
                type: "bytes",
              },
            ],
            internalType: "struct LibNFTOrder.Fee[]",
            name: "fees",
            type: "tuple[]",
          },
          {
            internalType: "contract IERC1155Token",
            name: "erc1155Token",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "erc1155TokenId",
            type: "uint256",
          },
          {
            components: [
              {
                internalType: "contract IPropertyValidator",
                name: "propertyValidator",
                type: "address",
              },
              {
                internalType: "bytes",
                name: "propertyData",
                type: "bytes",
              },
            ],
            internalType: "struct LibNFTOrder.Property[]",
            name: "erc1155TokenProperties",
            type: "tuple[]",
          },
          {
            internalType: "uint128",
            name: "erc1155TokenAmount",
            type: "uint128",
          },
        ],
        internalType: "struct LibNFTOrder.ERC1155Order",
        name: "order",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "enum LibSignature.SignatureType",
            name: "signatureType",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "v",
            type: "uint8",
          },
          {
            internalType: "bytes32",
            name: "r",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "s",
            type: "bytes32",
          },
        ],
        internalType: "struct LibSignature.Signature",
        name: "signature",
        type: "tuple",
      },
    ],
    name: "validateERC1155OrderSignature",
    outputs: [],
    stateMutability: "view",
    type: "function",
  },
];

export class IERC1155OrdersFeature__factory {
  static readonly abi = _abi;
  static createInterface(): IERC1155OrdersFeatureInterface {
    return new utils.Interface(_abi) as IERC1155OrdersFeatureInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IERC1155OrdersFeature {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as IERC1155OrdersFeature;
  }
}
