/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type {
  IMooniswapPool,
  IMooniswapPoolInterface,
} from "../IMooniswapPool";

const _abi = [
  {
    inputs: [
      {
        internalType: "contract IERC20TokenV06",
        name: "sellToken",
        type: "address",
      },
      {
        internalType: "contract IERC20TokenV06",
        name: "buyToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "sellAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "minBoughtAmount",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "referrer",
        type: "address",
      },
    ],
    name: "swap",
    outputs: [
      {
        internalType: "uint256",
        name: "boughtAmount",
        type: "uint256",
      },
    ],
    stateMutability: "payable",
    type: "function",
  },
];

export class IMooniswapPool__factory {
  static readonly abi = _abi;
  static createInterface(): IMooniswapPoolInterface {
    return new utils.Interface(_abi) as IMooniswapPoolInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IMooniswapPool {
    return new Contract(address, _abi, signerOrProvider) as IMooniswapPool;
  }
}
