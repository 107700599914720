/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import { Provider, TransactionRequest } from "@ethersproject/providers";
import type { MixinCompound, MixinCompoundInterface } from "../MixinCompound";

const _abi = [
  {
    inputs: [
      {
        internalType: "contract IEtherTokenV06",
        name: "weth",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
];

const _bytecode =
  "0x60a060405234801561001057600080fd5b5060405161018d38038061018d83398181016040528101906100329190610108565b6100647f5adac77f06cc3ec1d766aaa9d14c25832b7b3f835db36c373cc5e30f751adebf60001b61010560201b60201c565b6100967f86d6dd67e3dc7b283694cba38b7ed8ce4bc7dc4a732a593ee0daad650980de4760001b61010560201b60201c565b6100c87f255481a7f56ec28de31acd730d17cd8879e465b1b7a8462b6bc9142d6492477660001b61010560201b60201c565b8073ffffffffffffffffffffffffffffffffffffffff1660808173ffffffffffffffffffffffffffffffffffffffff1660601b8152505050610138565b50565b600060208284031215610119578081fd5b815160018060a01b038116811461012e578182fd5b8091505092915050565b60805160601c603f61014e60003950603f6000f3fe6080604052600080fdfea26469706673582212200af36124a982fe455eaefbc75f8e6a5832572aaf6ed51169203c207e1512eaef64736f6c634300060c0033";

export class MixinCompound__factory extends ContractFactory {
  constructor(
    ...args: [signer: Signer] | ConstructorParameters<typeof ContractFactory>
  ) {
    if (args.length === 1) {
      super(_abi, _bytecode, args[0]);
    } else {
      super(...args);
    }
  }

  deploy(
    weth: string,
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<MixinCompound> {
    return super.deploy(weth, overrides || {}) as Promise<MixinCompound>;
  }
  getDeployTransaction(
    weth: string,
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(weth, overrides || {});
  }
  attach(address: string): MixinCompound {
    return super.attach(address) as MixinCompound;
  }
  connect(signer: Signer): MixinCompound__factory {
    return super.connect(signer) as MixinCompound__factory;
  }
  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): MixinCompoundInterface {
    return new utils.Interface(_abi) as MixinCompoundInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): MixinCompound {
    return new Contract(address, _abi, signerOrProvider) as MixinCompound;
  }
}
