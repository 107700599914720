/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type {
  IBalancerV2BatchSwapVault,
  IBalancerV2BatchSwapVaultInterface,
} from "../IBalancerV2BatchSwapVault";

const _abi = [
  {
    inputs: [
      {
        internalType: "enum IBalancerV2BatchSwapVault.SwapKind",
        name: "kind",
        type: "uint8",
      },
      {
        components: [
          {
            internalType: "bytes32",
            name: "poolId",
            type: "bytes32",
          },
          {
            internalType: "uint256",
            name: "assetInIndex",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "assetOutIndex",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
          {
            internalType: "bytes",
            name: "userData",
            type: "bytes",
          },
        ],
        internalType: "struct IBalancerV2BatchSwapVault.BatchSwapStep[]",
        name: "swaps",
        type: "tuple[]",
      },
      {
        internalType: "contract IERC20TokenV06[]",
        name: "assets",
        type: "address[]",
      },
      {
        components: [
          {
            internalType: "address",
            name: "sender",
            type: "address",
          },
          {
            internalType: "bool",
            name: "fromInternalBalance",
            type: "bool",
          },
          {
            internalType: "address payable",
            name: "recipient",
            type: "address",
          },
          {
            internalType: "bool",
            name: "toInternalBalance",
            type: "bool",
          },
        ],
        internalType: "struct IBalancerV2BatchSwapVault.FundManagement",
        name: "funds",
        type: "tuple",
      },
      {
        internalType: "int256[]",
        name: "limits",
        type: "int256[]",
      },
      {
        internalType: "uint256",
        name: "deadline",
        type: "uint256",
      },
    ],
    name: "batchSwap",
    outputs: [
      {
        internalType: "int256[]",
        name: "amounts",
        type: "int256[]",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export class IBalancerV2BatchSwapVault__factory {
  static readonly abi = _abi;
  static createInterface(): IBalancerV2BatchSwapVaultInterface {
    return new utils.Interface(_abi) as IBalancerV2BatchSwapVaultInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IBalancerV2BatchSwapVault {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as IBalancerV2BatchSwapVault;
  }
}
