import { BigNumber } from "ethers";

let key = 0;
export function getUniqKey() {
    return ++key;
}

export function timestampInSecond() {
    return Math.floor(new Date().getTime() / 1000);
}

export function sleep(milliseconds: number) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

export const ETHER = BigInt(1e18);
export const MINUTE = 60;
export const HOUR = MINUTE * 60;
export const DAY = HOUR * 24;
export const MONTH = DAY * 30;
export const YEAR = DAY * 365;

export const UINT256_MAX = BigNumber.from("115792089237316195423570985008687907853269984665640564039457584007913129639935");

export const NULL_ADDRESS = "0x0000000000000000000000000000000000000000";
export const NATIVE_TOKEN_ADDRESS = "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE";

export const CODE_ERC721: number = 10000;
export const CODE_ERC1155: number = 20000;
export const CODE_AUCTION: number = 30000;

export enum SignatureType {
    ILLEGAL,
    INVALID,
    EIP712,
    ETHSIGN,
    PRESIGNED
}

export enum OrderStatus {
    INVALID,
    FILLABLE,
    UNFILLABLE,
    EXPIRED
}

export enum TradeDirection {
    SELL_NFT,
    BUY_NFT,
}

export enum NFTAuctionKind {
    ENGLISH,
    DUTCH
}

export enum NFTKind {
    ERC721,
    ERC1155
}

export enum AuctionStatus {
    INVALID_AMOUNT_SETTINGS,
    FILLABLE,
    UNFILLABLE,
    NOT_START,
    EXPIRED,
    INVALID_TIME_SETTINGS,
    INVALID_ERC1155_TOKEN_AMOUNT,
    INVALID_ERC721_TOKEN_AMOUNT,
    NATIVE_TOKEN_NOT_ALLOWED,
    TAKE_TOO_EARLY
}

export const SIGNATURE_ABI = [
    { type: 'uint8', name: 'signatureType' },
    { type: 'uint8', name: 'v' },
    { type: 'bytes32', name: 'r' },
    { type: 'bytes32', name: 's' },
];

export const AUCTION_FEE_ABI = [
    { type: 'bool', name: 'isMatcher' },
    { type: 'address', name: 'recipient' },
    { type: 'uint256', name: 'amountOrRate' },
    { type: 'bytes', name: 'feeData' },
];

export const FEE_ABI = [
    { type: 'address', name: 'recipient' },
    { type: 'uint256', name: 'amount' },
    { type: 'bytes', name: 'feeData' },
];

export const PROPERTY_ABI = [
    { type: 'address', name: 'propertyValidator' },
    { type: 'bytes', name: 'propertyData' },
];

export const ERC1155ORDER_STRUCT_ABI = [
    { type: 'uint8', name: 'direction' },
    { type: 'address', name: 'maker' },
    { type: 'address', name: 'taker' },
    { type: 'uint256', name: 'expiry' },
    { type: 'uint256', name: 'nonce' },
    { type: 'address', name: 'erc20Token' },
    { type: 'uint256', name: 'erc20TokenAmount' },
    { type: 'Fee[]', name: 'fees' },
    { type: 'address', name: 'erc1155Token' },
    { type: 'uint256', name: 'erc1155TokenId' },
    { type: 'Property[]', name: 'erc1155TokenProperties' },
    { type: 'uint128', name: 'erc1155TokenAmount' },
];

export const ERC721ORDER_STRUCT_ABI = [
    { type: 'uint8', name: 'direction' },
    { type: 'address', name: 'maker' },
    { type: 'address', name: 'taker' },
    { type: 'uint256', name: 'expiry' },
    { type: 'uint256', name: 'nonce' },
    { type: 'address', name: 'erc20Token' },
    { type: 'uint256', name: 'erc20TokenAmount' },
    { type: 'Fee[]', name: 'fees' },
    { type: 'address', name: 'erc721Token' },
    { type: 'uint256', name: 'erc721TokenId' },
    { type: 'Property[]', name: 'erc721TokenProperties' },
];

export const AUCTION_STRUCT_ABI = [
    { type: 'uint8', name: 'auctionKind' },
    { type: 'uint8', name: 'nftKind' },
    { type: 'bool', name: 'earlyMatch' },
    { type: 'address', name: 'maker' },
    { type: 'uint128', name: 'startTime' },
    { type: 'uint128', name: 'endTime' },
    { type: 'uint256', name: 'nonce' },
    { type: 'address', name: 'erc20Token' },
    { type: 'uint256', name: 'startErc20TokenAmount' },
    { type: 'uint256', name: 'endOrReservedErc20TokenAmount' },
    { type: 'AuctionFee[]', name: 'fees' },
    { type: 'address', name: 'nftToken' },
    { type: 'uint256', name: 'nftTokenId' },
    { type: 'uint128', name: 'nftTokenAmount' },
];

export const EN_AUCTION_BID_STRUCT_ABI = [
    { type: 'NFTAuction', name: 'auction' },
    { type: 'address', name: 'bidMaker' },
    { type: 'uint256', name: 'erc20TokenAmount' },
];
