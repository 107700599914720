import React, { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { Assets } from './Assets';
import { DutchAuction } from './nft/DutchAuction';
import { BroadcastOffer } from './nft/BroadcastOffer';

import {
  getAccount, getBlockNumber, getNetwork,
  selectAccountAddress, selectCurrentBlockNumber,
  selectEthereum,
  selectNetwork,
  setProvider,
} from './dexSlice';
import { Listing } from './nft/Listing';
import { Offer } from './nft/Offer';
import { TransferNFTs } from './nft/TransferNFTs';
import { EnglishAuction } from './nft/EnglishAuction';

declare global {
  interface Window {
    ethereum: any;
  }
}

function ConnectWallet(props: any) {
  if (props.a) {
    return (
      <div></div>
    )
  } else {
    return (
      <button onClick={props.f}>Connect Wallet</button>
    )
  }
}

export function Dex(_props: any) {
  const dispatch = useAppDispatch();
  const currentBlockNumber = useAppSelector(selectCurrentBlockNumber);
  const accountAddress = useAppSelector(selectAccountAddress);
  const ethereum = useAppSelector(selectEthereum);
  const network = useAppSelector(selectNetwork);

  function init() {
    window.ethereum.on('accountsChanged', function (_accounts: [any]) {
      dispatch(getAccount());
    });
    window.ethereum.on('chainChanged', function (_chainId: string) {
      dispatch(setProvider(window.ethereum));
      dispatch(getNetwork());
    });
    dispatch(setProvider(window.ethereum));
    dispatch(getAccount());
    dispatch(getBlockNumber());
    dispatch(getNetwork());
  }

  return (
    <div>
      <ConnectWallet f={init} a={ethereum}></ConnectWallet> <br></br>
      Account {accountAddress} <br></br>
      network {network?.name} <br></br>
      BlockNumber {currentBlockNumber}<br></br>
      <br></br>
      <Assets></Assets> <br></br>
      <EnglishAuction></EnglishAuction><br></br>
      <DutchAuction></DutchAuction><br></br>
      <Listing></Listing><br></br>
      <Offer></Offer><br></br>
      <BroadcastOffer></BroadcastOffer><br></br>
      <TransferNFTs></TransferNFTs><br></br>
    </div>
  );
}
