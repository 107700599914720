/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type {
  INFTToolsFeature,
  INFTToolsFeatureInterface,
} from "../INFTToolsFeature";

const _abi = [
  {
    inputs: [
      {
        components: [
          {
            internalType: "contract IERC721Token",
            name: "token",
            type: "address",
          },
          {
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
          },
        ],
        internalType: "struct INFTToolsFeature.TransferERC721AsseetsParam[]",
        name: "erc721s",
        type: "tuple[]",
      },
      {
        components: [
          {
            internalType: "contract IERC1155Token",
            name: "token",
            type: "address",
          },
          {
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        internalType: "struct INFTToolsFeature.TransferERC1155AsseetsParam[]",
        name: "erc1155s",
        type: "tuple[]",
      },
    ],
    name: "batchTransferNFTAssetsFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export class INFTToolsFeature__factory {
  static readonly abi = _abi;
  static createInterface(): INFTToolsFeatureInterface {
    return new utils.Interface(_abi) as INFTToolsFeatureInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): INFTToolsFeature {
    return new Contract(address, _abi, signerOrProvider) as INFTToolsFeature;
  }
}
