/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type {
  INFTAuctionsFeature,
  INFTAuctionsFeatureInterface,
} from "../INFTAuctionsFeature";

const _abi = [
  {
    inputs: [
      {
        components: [
          {
            components: [
              {
                internalType: "enum INFTAuctionsFeature.NFTAuctionKind",
                name: "auctionKind",
                type: "uint8",
              },
              {
                internalType: "enum INFTAuctionsFeature.NFTKind",
                name: "nftKind",
                type: "uint8",
              },
              {
                internalType: "bool",
                name: "earlyMatch",
                type: "bool",
              },
              {
                internalType: "address",
                name: "maker",
                type: "address",
              },
              {
                internalType: "uint128",
                name: "startTime",
                type: "uint128",
              },
              {
                internalType: "uint128",
                name: "endTime",
                type: "uint128",
              },
              {
                internalType: "uint256",
                name: "nonce",
                type: "uint256",
              },
              {
                internalType: "address",
                name: "erc20Token",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "startErc20TokenAmount",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "endOrReservedErc20TokenAmount",
                type: "uint256",
              },
              {
                components: [
                  {
                    internalType: "bool",
                    name: "isMatcher",
                    type: "bool",
                  },
                  {
                    internalType: "address",
                    name: "recipient",
                    type: "address",
                  },
                  {
                    internalType: "uint256",
                    name: "amountOrRate",
                    type: "uint256",
                  },
                  {
                    internalType: "bytes",
                    name: "feeData",
                    type: "bytes",
                  },
                ],
                internalType: "struct INFTAuctionsFeature.AuctionFee[]",
                name: "fees",
                type: "tuple[]",
              },
              {
                internalType: "address",
                name: "nftToken",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "nftTokenId",
                type: "uint256",
              },
              {
                internalType: "uint128",
                name: "nftTokenAmount",
                type: "uint128",
              },
            ],
            internalType: "struct INFTAuctionsFeature.NFTAuction",
            name: "auction",
            type: "tuple",
          },
          {
            internalType: "address",
            name: "bidMaker",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "erc20TokenAmount",
            type: "uint256",
          },
        ],
        internalType: "struct INFTAuctionsFeature.EnglishAuctionBid",
        name: "enBid",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "enum INFTAuctionsFeature.SignatureType",
            name: "signatureType",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "v",
            type: "uint8",
          },
          {
            internalType: "bytes32",
            name: "r",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "s",
            type: "bytes32",
          },
        ],
        internalType: "struct INFTAuctionsFeature.Signature",
        name: "auctionSig",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "enum INFTAuctionsFeature.SignatureType",
            name: "signatureType",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "v",
            type: "uint8",
          },
          {
            internalType: "bytes32",
            name: "r",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "s",
            type: "bytes32",
          },
        ],
        internalType: "struct INFTAuctionsFeature.Signature",
        name: "bidSig",
        type: "tuple",
      },
      {
        internalType: "bool",
        name: "unwrapNativeToken",
        type: "bool",
      },
      {
        internalType: "bytes",
        name: "takerCallbackData",
        type: "bytes",
      },
    ],
    name: "acceptBid",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "enum INFTAuctionsFeature.NFTAuctionKind",
            name: "auctionKind",
            type: "uint8",
          },
          {
            internalType: "enum INFTAuctionsFeature.NFTKind",
            name: "nftKind",
            type: "uint8",
          },
          {
            internalType: "bool",
            name: "earlyMatch",
            type: "bool",
          },
          {
            internalType: "address",
            name: "maker",
            type: "address",
          },
          {
            internalType: "uint128",
            name: "startTime",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "endTime",
            type: "uint128",
          },
          {
            internalType: "uint256",
            name: "nonce",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "erc20Token",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "startErc20TokenAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "endOrReservedErc20TokenAmount",
            type: "uint256",
          },
          {
            components: [
              {
                internalType: "bool",
                name: "isMatcher",
                type: "bool",
              },
              {
                internalType: "address",
                name: "recipient",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "amountOrRate",
                type: "uint256",
              },
              {
                internalType: "bytes",
                name: "feeData",
                type: "bytes",
              },
            ],
            internalType: "struct INFTAuctionsFeature.AuctionFee[]",
            name: "fees",
            type: "tuple[]",
          },
          {
            internalType: "address",
            name: "nftToken",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "nftTokenId",
            type: "uint256",
          },
          {
            internalType: "uint128",
            name: "nftTokenAmount",
            type: "uint128",
          },
        ],
        internalType: "struct INFTAuctionsFeature.NFTAuction",
        name: "dutchAuction",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "enum INFTAuctionsFeature.SignatureType",
            name: "signatureType",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "v",
            type: "uint8",
          },
          {
            internalType: "bytes32",
            name: "r",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "s",
            type: "bytes32",
          },
        ],
        internalType: "struct INFTAuctionsFeature.Signature",
        name: "signature",
        type: "tuple",
      },
      {
        internalType: "uint128",
        name: "bidAmount",
        type: "uint128",
      },
      {
        internalType: "bytes",
        name: "callbackData",
        type: "bytes",
      },
    ],
    name: "bidNFTDutchAuction",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
];

export class INFTAuctionsFeature__factory {
  static readonly abi = _abi;
  static createInterface(): INFTAuctionsFeatureInterface {
    return new utils.Interface(_abi) as INFTAuctionsFeatureInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): INFTAuctionsFeature {
    return new Contract(address, _abi, signerOrProvider) as INFTAuctionsFeature;
  }
}
