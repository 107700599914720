/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type {
  IPancakeSwapFeature,
  IPancakeSwapFeatureInterface,
} from "../IPancakeSwapFeature";

const _abi = [
  {
    inputs: [
      {
        internalType: "contract IERC20TokenV06[]",
        name: "tokens",
        type: "address[]",
      },
      {
        internalType: "uint256",
        name: "sellAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "minBuyAmount",
        type: "uint256",
      },
      {
        internalType: "enum IPancakeSwapFeature.ProtocolFork",
        name: "fork",
        type: "uint8",
      },
    ],
    name: "sellToPancakeSwap",
    outputs: [
      {
        internalType: "uint256",
        name: "buyAmount",
        type: "uint256",
      },
    ],
    stateMutability: "payable",
    type: "function",
  },
];

export class IPancakeSwapFeature__factory {
  static readonly abi = _abi;
  static createInterface(): IPancakeSwapFeatureInterface {
    return new utils.Interface(_abi) as IPancakeSwapFeatureInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IPancakeSwapFeature {
    return new Contract(address, _abi, signerOrProvider) as IPancakeSwapFeature;
  }
}
