import { ethers } from 'ethers';
import { TradeCost } from './dex-utils';

export function ShowPrice(props: any) {
  if (!props.costs) return (<div><p>list ERC1155 to show price here</p></div>);
  const costs: TradeCost[] = props.costs;
  const list = [];
  const formatEther = ethers.utils.formatEther;
  for (const cost of costs) {
    list.push((<tr>
      <td>{formatEther(cost.totalCost)}</td>
      <td>{formatEther(cost.priceCost)}</td>
      <td>{formatEther(cost.feeCost)}</td>
      <td>{cost.nftTokenAmount.toString()}</td>
      <td>{cost.amount.toString()}</td>
    </tr>))
  }
  return (
    <div>
      <h3>ERC1155 price table</h3>
      <table>
        <tr>
          <th>totalCost</th>
          <th>priceCost</th>
          <th>feeCost</th>
          <th>totalAmount</th>
          <th>amount</th>
        </tr>
        {list}
      </table>
    </div>
  );
}
