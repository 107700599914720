/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type {
  MultiplexLiquidityProvider,
  MultiplexLiquidityProviderInterface,
} from "../MultiplexLiquidityProvider";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "inputToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "outputToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "inputTokenAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "outputTokenAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "provider",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "recipient",
        type: "address",
      },
    ],
    name: "LiquidityProviderSwap",
    type: "event",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "contract IERC20TokenV06",
            name: "inputToken",
            type: "address",
          },
          {
            internalType: "contract IERC20TokenV06",
            name: "outputToken",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "sellAmount",
            type: "uint256",
          },
          {
            components: [
              {
                internalType: "enum IMultiplexFeature.MultiplexSubcall",
                name: "id",
                type: "uint8",
              },
              {
                internalType: "uint256",
                name: "sellAmount",
                type: "uint256",
              },
              {
                internalType: "bytes",
                name: "data",
                type: "bytes",
              },
            ],
            internalType: "struct IMultiplexFeature.BatchSellSubcall[]",
            name: "calls",
            type: "tuple[]",
          },
          {
            internalType: "bool",
            name: "useSelfBalance",
            type: "bool",
          },
          {
            internalType: "address",
            name: "recipient",
            type: "address",
          },
        ],
        internalType: "struct IMultiplexFeature.BatchSellParams",
        name: "params",
        type: "tuple",
      },
      {
        internalType: "bytes",
        name: "wrappedCallData",
        type: "bytes",
      },
      {
        internalType: "uint256",
        name: "sellAmount",
        type: "uint256",
      },
    ],
    name: "_batchSellLiquidityProviderExternal",
    outputs: [
      {
        internalType: "uint256",
        name: "boughtAmount",
        type: "uint256",
      },
    ],
    stateMutability: "payable",
    type: "function",
  },
];

export class MultiplexLiquidityProvider__factory {
  static readonly abi = _abi;
  static createInterface(): MultiplexLiquidityProviderInterface {
    return new utils.Interface(_abi) as MultiplexLiquidityProviderInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): MultiplexLiquidityProvider {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as MultiplexLiquidityProvider;
  }
}
