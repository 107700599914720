/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import { Provider, TransactionRequest } from "@ethersproject/providers";
import type { MixinCurve, MixinCurveInterface } from "../MixinCurve";

const _abi = [
  {
    inputs: [
      {
        internalType: "contract IEtherTokenV06",
        name: "weth",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
];

const _bytecode =
  "0x60a060405234801561001057600080fd5b5060405161018d38038061018d83398181016040528101906100329190610108565b6100647fd9648e8aac5b23c3ec63b6785bb49ae81a446026be6560a4590f0e1319497a0b60001b61010560201b60201c565b6100967f51917f0c4d26d1d61fb3f179c50a8224bbc59e92e63e751120afd81432191b0660001b61010560201b60201c565b6100c87f1d4f31cbc974b491aba90b4884b0c06b9fa0256ffc4e5401c4addab1f1357fd460001b61010560201b60201c565b8073ffffffffffffffffffffffffffffffffffffffff1660808173ffffffffffffffffffffffffffffffffffffffff1660601b8152505050610138565b50565b600060208284031215610119578081fd5b815160018060a01b038116811461012e578182fd5b8091505092915050565b60805160601c603f61014e60003950603f6000f3fe6080604052600080fdfea264697066735822122083174b757e37f2ade565c0320db70d29139345aa28ae67dc2aac813bd82a000064736f6c634300060c0033";

export class MixinCurve__factory extends ContractFactory {
  constructor(
    ...args: [signer: Signer] | ConstructorParameters<typeof ContractFactory>
  ) {
    if (args.length === 1) {
      super(_abi, _bytecode, args[0]);
    } else {
      super(...args);
    }
  }

  deploy(
    weth: string,
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<MixinCurve> {
    return super.deploy(weth, overrides || {}) as Promise<MixinCurve>;
  }
  getDeployTransaction(
    weth: string,
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(weth, overrides || {});
  }
  attach(address: string): MixinCurve {
    return super.attach(address) as MixinCurve;
  }
  connect(signer: Signer): MixinCurve__factory {
    return super.connect(signer) as MixinCurve__factory;
  }
  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): MixinCurveInterface {
    return new utils.Interface(_abi) as MixinCurveInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): MixinCurve {
    return new Contract(address, _abi, signerOrProvider) as MixinCurve;
  }
}
