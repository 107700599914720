/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type {
  LibTransformERC20RichErrors1,
  LibTransformERC20RichErrors1Interface,
} from "../LibTransformERC20RichErrors1";

const _abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "buyToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "boughtAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "buyAmount",
        type: "uint256",
      },
    ],
    name: "IncompleteFillBuyQuoteError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sellToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "soldAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "sellAmount",
        type: "uint256",
      },
    ],
    name: "IncompleteFillSellQuoteError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "outputToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "outputTokenAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "minOutputTokenAmount",
        type: "uint256",
      },
    ],
    name: "IncompleteTransformERC20Error",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "ethAttached",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "ethNeeded",
        type: "uint256",
      },
    ],
    name: "InsufficientEthAttachedError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "ethBalance",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "ethNeeded",
        type: "uint256",
      },
    ],
    name: "InsufficientProtocolFeeError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenBalance",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "tokensNeeded",
        type: "uint256",
      },
    ],
    name: "InsufficientTakerTokenError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes",
        name: "assetData",
        type: "bytes",
      },
    ],
    name: "InvalidERC20AssetDataError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "actualContext",
        type: "address",
      },
      {
        internalType: "address",
        name: "expectedContext",
        type: "address",
      },
    ],
    name: "InvalidExecutionContextError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
    ],
    name: "InvalidTakerFeeTokenError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType:
          "enum LibTransformERC20RichErrors1.InvalidTransformDataErrorCode",
        name: "errorCode",
        type: "uint8",
      },
      {
        internalType: "bytes",
        name: "transformData",
        type: "bytes",
      },
    ],
    name: "InvalidTransformDataError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "outputToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "outputTokenLostAmount",
        type: "uint256",
      },
    ],
    name: "NegativeTransformERC20OutputError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "caller",
        type: "address",
      },
      {
        internalType: "address",
        name: "deployer",
        type: "address",
      },
    ],
    name: "OnlyCallableByDeployerError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "transformer",
        type: "address",
      },
      {
        internalType: "bytes",
        name: "transformerData",
        type: "bytes",
      },
      {
        internalType: "bytes",
        name: "resultData",
        type: "bytes",
      },
    ],
    name: "TransformerFailedError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
];

export class LibTransformERC20RichErrors1__factory {
  static readonly abi = _abi;
  static createInterface(): LibTransformERC20RichErrors1Interface {
    return new utils.Interface(_abi) as LibTransformERC20RichErrors1Interface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): LibTransformERC20RichErrors1 {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as LibTransformERC20RichErrors1;
  }
}
