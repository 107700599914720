import { IZeroEx__factory, MockERC1155__factory, MockERC721__factory } from "../typechain";
import React, { useState } from 'react';
import { useAppSelector } from '../../../app/hooks';
import { selectNetwork, selectProvider } from "../dexSlice";
import styles from '../Assets.module.css';
import {
  getZEROEXAddress
} from "../contracts";
import {
  DAY, NATIVE_TOKEN_ADDRESS, NULL_ADDRESS,
  timestampInSecond, TradeDirection
} from "../utils";
import {
  b, TradeCost, erc1155SellOrderPrice,
  ERC721Order, signTypedDataERC721, Signature, ERC1155Order, signTypedDataERC1155
} from "../dex-utils";
import { BigNumber, ethers } from "ethers";
import { ShowPrice } from "../Components";

export function Listing(_props: any) {
  const [listings, setListings] = useState<string>();

  const [tokenIDs, setTokenIDs] = useState<string>("0,1");
  const [tokenIDs1155, setTokenIDs1155] = useState<string>("0,1");
  const [payToken, setPayToken] = useState<string>(NATIVE_TOKEN_ADDRESS);
  const [feeAddress, setFeeAddress] = useState<string>(NULL_ADDRESS);
  const network = useAppSelector(selectNetwork);

  const provider = useAppSelector(selectProvider);
  const maker = provider.getSigner();
  const taker = provider.getSigner();
  const dex = IZeroEx__factory.connect(getZEROEXAddress(), provider);
  const azuki = MockERC721__factory.connect("0x9B90f643dA4bC61386B431Cd0877acA9f834284d", provider);
  const opendao = MockERC1155__factory.connect("0xfbDcF4A63A910B720f8cfe9390E9543B0D3d9275", provider);
  let sec = timestampInSecond();

  async function list721() {
    const listings = [];
    const ids = tokenIDs.split(",").map(i => Number(i));
    for (let i = 0; i < ids.length; ++i) {
      const order: ERC721Order = {
        direction: TradeDirection.SELL_NFT,
        maker: await maker.getAddress(),
        taker: NULL_ADDRESS,
        expiry: timestampInSecond() + DAY,
        nonce: ++sec,
        erc20Token: payToken,
        erc20TokenAmount: b("0.02"),
        fees: [{
          recipient: feeAddress,
          amount: b("0.01"),
          feeData: "0x",
        }].filter(e => e.recipient !== NULL_ADDRESS),
        erc721Token: azuki.address,
        erc721TokenId: ids[i],
        erc721TokenProperties: [],
      };
      const signature = await signTypedDataERC721(network?.chainId || 0, maker, dex, order);
      listings.push({ order, signature });
    }
    setListings(JSON.stringify(listings));
  }

  async function buy721() {
    const utils = ethers.utils;
    if (!listings) return;
    const _listings: { order: ERC721Order, signature: Signature }[] = JSON.parse(listings);
    // await dex.connect(taker).buyERC721(listing.sellOrder, listing.signature, arrayify("0x"), { value: price })
    await dex.connect(taker).batchBuyERC721sWithMaxNumber(
      _listings.map(i => i.order),
      _listings.map(i => i.signature),
      _listings.map(_i => "0x"), false, _listings.length, {
      maxFeePerGas: utils.parseUnits("200", "gwei"),
      maxPriorityFeePerGas: utils.parseUnits("1.5", "gwei"),
      value: b("0.02").add(b("0.01")).mul(_listings.length),
    });
  }

  async function cancel721() {
    if (!listings) return;
    const _listings: { order: ERC721Order, signature: Signature }[] = JSON.parse(listings);
    // await dex.connect(taker).cancelERC721Order(_listings[0].order.nonce);
    await dex.connect(taker).batchCancelERC721Orders(_listings.map(i => i.order.nonce));
  }

  const [prices, setPrices] = useState<TradeCost[]>();
  async function list1155() {
    const erc1155TokenAmount = BigNumber.from(10);
    const listings = [];
    const ids = tokenIDs.split(",").map(i => Number(i));
    for (let i = 0; i < ids.length; ++i) {
      const order: ERC1155Order = {
        direction: TradeDirection.SELL_NFT,
        maker: await maker.getAddress(),
        taker: NULL_ADDRESS,
        expiry: timestampInSecond() + DAY,
        nonce: ++sec,
        erc20Token: payToken,
        erc20TokenAmount: b("0.02"),
        fees: [{
          recipient: feeAddress,
          amount: b("0.01"),
          feeData: "0x",
        }].filter(e => e.recipient !== NULL_ADDRESS),
        erc1155Token: opendao.address,
        erc1155TokenId: ids[i],
        erc1155TokenAmount,
        erc1155TokenProperties: [],
      };
      const signature = await signTypedDataERC1155(network?.chainId || 0, maker, dex, order);
      listings.push({ order, signature });
    }
    setListings(JSON.stringify(listings));
    setPrices([
      erc1155SellOrderPrice(listings[0].order, erc1155TokenAmount.div(3)),
      erc1155SellOrderPrice(listings[0].order, erc1155TokenAmount.div(2)),
      erc1155SellOrderPrice(listings[0].order, erc1155TokenAmount),
    ]);
  }

  async function buy1155() {
    if (!listings) return;
    const _listings: { order: ERC1155Order, signature: Signature }[] = JSON.parse(listings);
    const utils = ethers.utils;
    // await dex.connect(taker).buyERC1155(listings1155.sellOrder, listings1155.signature, listings1155.sellOrder.erc1155TokenAmount arrayify("0x"), { value: price })
    await dex.connect(taker).batchBuyERC1155sWithMaxNumber(
      _listings.map(i => i.order),
      _listings.map(i => i.signature),
      _listings.map(i => i.order.erc1155TokenAmount),
      _listings.map(_i => "0x"), false, _listings.length, {
      maxFeePerGas: utils.parseUnits("200", "gwei"),
      maxPriorityFeePerGas: utils.parseUnits("1.5", "gwei"),
      value: b("0.02").add(b("0.01")).mul(_listings.length),
    });
  }

  async function cancel1155() {
    if (!listings) return;
    const _listings: { order: ERC721Order, signature: Signature }[] = JSON.parse(listings);
    // await dex.connect(taker).cancelERC1155Order(_listings[0].order.nonce);
    await dex.connect(taker).batchCancelERC1155Orders(_listings.map(i => i.order.nonce));
  }

  return (
    <div className={styles.border}>
      <h2>Sell NFT</h2>
      <label>erc721 token ids:</label> <input value={tokenIDs} onChange={(e) => setTokenIDs(e.target.value)}></input><br></br>
      <label>erc1155 token ids:</label> <input value={tokenIDs1155} onChange={(e) => setTokenIDs1155(e.target.value)}></input><br></br>
      <label>pay token:</label> <input value={payToken} onChange={(e) => setPayToken(e.target.value)} size={40}></input><br></br>
      <label>fee address:</label> <input value={feeAddress} onChange={(e) => setFeeAddress(e.target.value)} size={40}></input><br></br>
      <label>listings:</label> <input value={listings} onChange={(e) => setListings(e.target.value)} size={40}></input><br></br>
      <button onClick={list721}>List erc721 SellOrders</button>
      <button onClick={buy721}>Buy erc721 SellOrders</button>
      <button onClick={cancel721}>Cancel erc721 SellOrders</button><br></br>
      <button onClick={list1155}>List erc1155 SellOrders</button>
      <button onClick={buy1155}>Buy erc1155 SellOrders</button>
      <button onClick={cancel1155}>Cancel erc1155 SellOrders</button><br></br>
      <ShowPrice costs={prices}></ShowPrice>
    </div>
  );
}
