/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type {
  LibMetaTransactionsRichErrors1,
  LibMetaTransactionsRichErrors1Interface,
} from "../LibMetaTransactionsRichErrors1";

const _abi = [
  {
    inputs: [
      {
        internalType: "uint256",
        name: "mtxCount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "signatureCount",
        type: "uint256",
      },
    ],
    name: "InvalidMetaTransactionsArrayLengthsError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "mtxHash",
        type: "bytes32",
      },
      {
        internalType: "uint256",
        name: "executedBlockNumber",
        type: "uint256",
      },
    ],
    name: "MetaTransactionAlreadyExecutedError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "mtxHash",
        type: "bytes32",
      },
      {
        internalType: "bytes",
        name: "callData",
        type: "bytes",
      },
      {
        internalType: "bytes",
        name: "returnData",
        type: "bytes",
      },
    ],
    name: "MetaTransactionCallFailedError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "mtxHash",
        type: "bytes32",
      },
      {
        internalType: "uint256",
        name: "time",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "expirationTime",
        type: "uint256",
      },
    ],
    name: "MetaTransactionExpiredError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "mtxHash",
        type: "bytes32",
      },
      {
        internalType: "uint256",
        name: "gasPrice",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "minGasPrice",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "maxGasPrice",
        type: "uint256",
      },
    ],
    name: "MetaTransactionGasPriceError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "mtxHash",
        type: "bytes32",
      },
      {
        internalType: "uint256",
        name: "ethBalance",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "ethRequired",
        type: "uint256",
      },
    ],
    name: "MetaTransactionInsufficientEthError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "mtxHash",
        type: "bytes32",
      },
      {
        internalType: "bytes",
        name: "signature",
        type: "bytes",
      },
      {
        internalType: "bytes",
        name: "errData",
        type: "bytes",
      },
    ],
    name: "MetaTransactionInvalidSignatureError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "mtxHash",
        type: "bytes32",
      },
      {
        internalType: "bytes4",
        name: "selector",
        type: "bytes4",
      },
    ],
    name: "MetaTransactionUnsupportedFunctionError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "mtxHash",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
      {
        internalType: "address",
        name: "expectedSender",
        type: "address",
      },
    ],
    name: "MetaTransactionWrongSenderError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
];

export class LibMetaTransactionsRichErrors1__factory {
  static readonly abi = _abi;
  static createInterface(): LibMetaTransactionsRichErrors1Interface {
    return new utils.Interface(_abi) as LibMetaTransactionsRichErrors1Interface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): LibMetaTransactionsRichErrors1 {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as LibMetaTransactionsRichErrors1;
  }
}
