/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type {
  LibSignatureRichErrors1,
  LibSignatureRichErrors1Interface,
} from "../LibSignatureRichErrors1";

const _abi = [
  {
    inputs: [
      {
        internalType:
          "enum LibSignatureRichErrors1.SignatureValidationErrorCodes",
        name: "code",
        type: "uint8",
      },
      {
        internalType: "bytes32",
        name: "hash",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "signerAddress",
        type: "address",
      },
      {
        internalType: "bytes",
        name: "signature",
        type: "bytes",
      },
    ],
    name: "SignatureValidationError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType:
          "enum LibSignatureRichErrors1.SignatureValidationErrorCodes",
        name: "code",
        type: "uint8",
      },
      {
        internalType: "bytes32",
        name: "hash",
        type: "bytes32",
      },
    ],
    name: "SignatureValidationError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
];

export class LibSignatureRichErrors1__factory {
  static readonly abi = _abi;
  static createInterface(): LibSignatureRichErrors1Interface {
    return new utils.Interface(_abi) as LibSignatureRichErrors1Interface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): LibSignatureRichErrors1 {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as LibSignatureRichErrors1;
  }
}
