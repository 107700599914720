/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type {
  LibReentrancyGuardRichErrorsV061,
  LibReentrancyGuardRichErrorsV061Interface,
} from "../LibReentrancyGuardRichErrorsV061";

const _abi = [
  {
    inputs: [],
    name: "ILLEGAL_REENTRANCY_ERROR_SELECTOR_BYTES",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "IllegalReentrancyError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
];

export class LibReentrancyGuardRichErrorsV061__factory {
  static readonly abi = _abi;
  static createInterface(): LibReentrancyGuardRichErrorsV061Interface {
    return new utils.Interface(
      _abi
    ) as LibReentrancyGuardRichErrorsV061Interface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): LibReentrancyGuardRichErrorsV061 {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as LibReentrancyGuardRichErrorsV061;
  }
}
