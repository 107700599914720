/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import { Provider, TransactionRequest } from "@ethersproject/providers";
import type { MixinBancor, MixinBancorInterface } from "../MixinBancor";

const _abi = [
  {
    inputs: [
      {
        internalType: "contract IEtherTokenV06",
        name: "weth",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [],
    name: "BANCOR_ETH_ADDRESS",
    outputs: [
      {
        internalType: "contract IERC20TokenV06",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

const _bytecode =
  "0x60a060405234801561001057600080fd5b5060405161020a38038061020a83398181016040528101906100329190610108565b6100647f90f477ed6a95d6d00b045b75b41a9b0cde8cdab1482b61c737a2f0adcda8c43760001b61010560201b60201c565b6100967f16b578ec148b691a2e1daf8ffe827f3ebfdbff5127613e64455848dee2b8a12960001b61010560201b60201c565b6100c87f65911227167c18d5de199998593615b0ba060b19979c27ad51718a87933f228260001b61010560201b60201c565b8073ffffffffffffffffffffffffffffffffffffffff1660808173ffffffffffffffffffffffffffffffffffffffff1660601b8152505050610138565b50565b600060208284031215610119578081fd5b815160018060a01b038116811461012e578182fd5b8091505092915050565b60805160601c60bc61014e6000395060bc6000f3fe6080604052348015600f57600080fd5b506004361060285760003560e01c80638dc669fa14602d575b600080fd5b60336047565b604051603e9190605f565b60405180910390f35b73eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee81565b600060208201905073ffffffffffffffffffffffffffffffffffffffff831682529291505056fea26469706673582212206d41a52f526683baa556d4a7ba0608be457585256adaaf13fa069d4d6f24403c64736f6c634300060c0033";

export class MixinBancor__factory extends ContractFactory {
  constructor(
    ...args: [signer: Signer] | ConstructorParameters<typeof ContractFactory>
  ) {
    if (args.length === 1) {
      super(_abi, _bytecode, args[0]);
    } else {
      super(...args);
    }
  }

  deploy(
    weth: string,
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<MixinBancor> {
    return super.deploy(weth, overrides || {}) as Promise<MixinBancor>;
  }
  getDeployTransaction(
    weth: string,
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(weth, overrides || {});
  }
  attach(address: string): MixinBancor {
    return super.attach(address) as MixinBancor;
  }
  connect(signer: Signer): MixinBancor__factory {
    return super.connect(signer) as MixinBancor__factory;
  }
  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): MixinBancorInterface {
    return new utils.Interface(_abi) as MixinBancorInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): MixinBancor {
    return new Contract(address, _abi, signerOrProvider) as MixinBancor;
  }
}
