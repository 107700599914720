/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type {
  LibNativeOrdersRichErrors1,
  LibNativeOrdersRichErrors1Interface,
} from "../LibNativeOrdersRichErrors1";

const _abi = [
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "orderHash",
        type: "bytes32",
      },
      {
        internalType: "uint256",
        name: "takerTokenFilledAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "takerTokenFillAmount",
        type: "uint256",
      },
    ],
    name: "BatchFillIncompleteError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "minValidSalt",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "oldMinValidSalt",
        type: "uint256",
      },
    ],
    name: "CancelSaltTooLowError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "orderHash",
        type: "bytes32",
      },
      {
        internalType: "uint256",
        name: "takerTokenFilledAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "takerTokenFillAmount",
        type: "uint256",
      },
    ],
    name: "FillOrKillFailedError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "maker",
        type: "address",
      },
      {
        internalType: "address",
        name: "signer",
        type: "address",
      },
    ],
    name: "InvalidSignerError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "orderHash",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
      {
        internalType: "address",
        name: "maker",
        type: "address",
      },
    ],
    name: "OnlyOrderMakerAllowed",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "orderHash",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "txOrigin",
        type: "address",
      },
      {
        internalType: "address",
        name: "orderTxOrigin",
        type: "address",
      },
    ],
    name: "OrderNotFillableByOriginError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "orderHash",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
      {
        internalType: "address",
        name: "orderSender",
        type: "address",
      },
    ],
    name: "OrderNotFillableBySenderError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "orderHash",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "taker",
        type: "address",
      },
      {
        internalType: "address",
        name: "orderTaker",
        type: "address",
      },
    ],
    name: "OrderNotFillableByTakerError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "orderHash",
        type: "bytes32",
      },
      {
        internalType: "uint8",
        name: "orderStatus",
        type: "uint8",
      },
    ],
    name: "OrderNotFillableError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "orderHash",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "signer",
        type: "address",
      },
      {
        internalType: "address",
        name: "maker",
        type: "address",
      },
    ],
    name: "OrderNotSignedByMakerError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "receiver",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "refundAmount",
        type: "uint256",
      },
    ],
    name: "ProtocolFeeRefundFailed",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
];

export class LibNativeOrdersRichErrors1__factory {
  static readonly abi = _abi;
  static createInterface(): LibNativeOrdersRichErrors1Interface {
    return new utils.Interface(_abi) as LibNativeOrdersRichErrors1Interface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): LibNativeOrdersRichErrors1 {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as LibNativeOrdersRichErrors1;
  }
}
