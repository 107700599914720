import { IZeroEx__factory, MockERC1155__factory, MockERC721__factory } from "../typechain";
import React, { useState } from 'react';
import { useAppSelector } from '../../../app/hooks';
import { selectNetwork, selectProvider } from "../dexSlice";
import styles from '../Assets.module.css';
import {
  getZEROEXAddress
} from "../contracts";
import {
  MINUTE,
  NATIVE_TOKEN_ADDRESS, NFTAuctionKind, NFTKind, NULL_ADDRESS,
  timestampInSecond
} from "../utils";
import {
  b, NFTAuction, Signature,
  signTypedDataAuction, TradeCost, auctionPriceWithFee
} from "../dex-utils";
import { BigNumber } from "ethers";
import { arrayify } from "ethers/lib/utils";
import { ShowPrice } from "../Components";

export function DutchAuction(_props: any) {
  const [listing, setListing] = useState<string>();

  const [tokenID, setTokenID] = useState<string>("0");
  const [tokenID1155, setTokenID1155] = useState<string>("0");
  const [payToken, setPayToken] = useState<string>(NATIVE_TOKEN_ADDRESS);
  const [feeAddress, setFeeAddress] = useState<string>(NULL_ADDRESS);
  const network = useAppSelector(selectNetwork);

  const provider = useAppSelector(selectProvider);
  const maker = provider.getSigner();
  const taker = provider.getSigner();

  const dex = IZeroEx__factory.connect(getZEROEXAddress(), provider);
  const azuki = MockERC721__factory.connect("0x9B90f643dA4bC61386B431Cd0877acA9f834284d", provider);
  const opendao = MockERC1155__factory.connect("0xfbDcF4A63A910B720f8cfe9390E9543B0D3d9275", provider);

  async function list721Auction() {
    const time = (await provider.getBlock("latest")).timestamp;
    const auction: NFTAuction = {
      auctionKind: NFTAuctionKind.DUTCH,
      nftKind: NFTKind.ERC721,
      maker: await maker.getAddress(),
      startTime: time,
      endTime: time + 10 * MINUTE,
      nonce: timestampInSecond(),
      erc20Token: payToken,
      startErc20TokenAmount: b("0.02"),
      endOrReservedErc20TokenAmount: b("0.01"),
      fees: [{
        isMatcher: false, // only used with English Auction.
        recipient: feeAddress,
        amountOrRate: b("0.01"),
        feeData: "0x",
      }].filter(e => e.recipient !== NULL_ADDRESS),
      nftToken: azuki.address,
      nftTokenId: BigNumber.from(tokenID),
      nftTokenAmount: 1, // always be one in ERC721 Auction.
      earlyMatch: false, // only used with English Auction.
    };
    const signature: Signature = await signTypedDataAuction(network?.chainId || 0, maker, dex, auction);
    const listing = { auction, signature };
    setListing(JSON.stringify(listing));
  }

  async function bid721Auction() {
    if (!listing) return;
    const _listing: { auction: NFTAuction, signature: Signature } = JSON.parse(listing);
    const bidAmount = 1; // always be one in ERC721 Auction.
    const cost = auctionPriceWithFee(_listing.auction, bidAmount, (await provider.getBlock("latest")).timestamp);

    const gasLimit = await dex.estimateGas.bidNFTDutchAuction(
      _listing.auction,
      _listing.signature,
      bidAmount,
      arrayify("0x"), { value: cost.totalCost, from: await taker.getAddress() }
    );

    await dex.connect(taker).bidNFTDutchAuction(
      _listing.auction,
      _listing.signature,
      bidAmount,
      arrayify("0x"), { value: cost.totalCost, gasLimit: gasLimit.add(gasLimit.div(10)) }
    );
  }

  async function cancel721() {
    if (!listing) return;
    const _listing: { auction: NFTAuction, signature: Signature } = JSON.parse(listing);
    // await dex.connect(taker).cancelAuction(_listing.auction.nonce);
    await dex.connect(taker).batchCancelAuctions([_listing.auction.nonce]);
  }

  async function list1155Auction() {
    const time = (await provider.getBlock("latest")).timestamp;
    const auction: NFTAuction = {
      auctionKind: NFTAuctionKind.DUTCH,
      nftKind: NFTKind.ERC1155,
      maker: await maker.getAddress(),
      startTime: time,
      endTime: time + 10 * MINUTE,
      nonce: timestampInSecond(),
      erc20Token: payToken,
      startErc20TokenAmount: b("0.02"),
      endOrReservedErc20TokenAmount: b("0.01"),
      fees: [{
        isMatcher: false, // only used with English Auction.
        recipient: feeAddress,
        amountOrRate: b("0.01"),
        feeData: "0x",
      }].filter(e => e.recipient !== NULL_ADDRESS),
      nftToken: opendao.address,
      nftTokenId: BigNumber.from(tokenID1155),
      nftTokenAmount: 10,
      earlyMatch: false, // only used with English Auction.
    };
    const signature: Signature = await signTypedDataAuction(network?.chainId || 0, maker, dex, auction);
    const listing = { auction, signature };
    setListing(JSON.stringify(listing));
  }

  const [prices, setPrices] = useState<TradeCost[]>();
  async function getCurrentPrices() {
    if (!listing) return;
    const _listing: { auction: NFTAuction, signature: Signature } = JSON.parse(listing);
    const time = (await provider.getBlock("latest")).timestamp;
    setPrices([
      auctionPriceWithFee(_listing.auction, BigNumber.from(_listing.auction.nftTokenAmount).div(7), time),
      auctionPriceWithFee(_listing.auction, BigNumber.from(_listing.auction.nftTokenAmount).div(3), time),
      auctionPriceWithFee(_listing.auction, BigNumber.from(_listing.auction.nftTokenAmount), time),
    ]);
  }

  async function bid1155Auction() {
    if (!listing) return;
    const _listing: { auction: NFTAuction, signature: Signature } = JSON.parse(listing);

    const bidAmount = 3; // can be differ from `_listing.auction.nftTokenAmount`
    const time = (await provider.getBlock("latest")).timestamp;
    const cost = auctionPriceWithFee(_listing.auction, bidAmount, time);

    const gasLimit = await dex.estimateGas.bidNFTDutchAuction(
      _listing.auction,
      _listing.signature,
      bidAmount,
      arrayify("0x"), { value: cost.totalCost, from: await taker.getAddress() }
    );

    await dex.connect(taker).bidNFTDutchAuction(
      _listing.auction,
      _listing.signature,
      bidAmount,
      arrayify("0x"), { value: cost.totalCost, gasLimit: gasLimit.add(gasLimit.div(10)) }
    );
  }

  async function cancel1155() {
    await cancel721();
  }

  return (
    <div className={styles.border}>
      <h2>Dutch Auction</h2>
      <label>erc721 token id:</label> <input value={tokenID} onChange={(e) => setTokenID(e.target.value)}></input><br></br>
      <label>erc1155 token id:</label> <input value={tokenID1155} onChange={(e) => setTokenID1155(e.target.value)}></input><br></br>
      <label>pay token:</label> <input value={payToken} onChange={(e) => setPayToken(e.target.value)} size={40}></input><br></br>
      <label>fee address:</label> <input value={feeAddress} onChange={(e) => setFeeAddress(e.target.value)} size={40}></input><br></br>
      <label>listing:</label> <input value={listing} onChange={(e) => setListing(e.target.value)} size={40}></input><br></br>
      <button onClick={list721Auction}>List erc721 DutchAuction</button>
      <button onClick={bid721Auction}>Bid erc721 DutchAuction</button>
      <button onClick={cancel721}>Cancel erc721 DutchAuction</button><br></br>
      <button onClick={list1155Auction}>List erc1155 DutchAuction</button>
      <button onClick={bid1155Auction}>Bid erc1155 DutchAuction</button>
      <button onClick={cancel1155}>Cancel erc1155 DutchAuction</button><br></br>
      <button onClick={getCurrentPrices}>Refresh Current Prices</button><br></br>
      <ShowPrice costs={prices}></ShowPrice>
    </div>
  );
}
