import { utils, BigNumber, Signer, BigNumberish, BytesLike } from "ethers";
import { IZeroEx } from "./typechain";
import {
    AUCTION_FEE_ABI,
    AUCTION_STRUCT_ABI,
    EN_AUCTION_BID_STRUCT_ABI, ERC1155ORDER_STRUCT_ABI,
    ERC721ORDER_STRUCT_ABI,
    FEE_ABI, MINUTE, NATIVE_TOKEN_ADDRESS, NFTAuctionKind,
    NFTKind, NULL_ADDRESS, PROPERTY_ABI, SignatureType,
} from "./utils";
import { timestampInSecond } from "./utils";
import { TypedDataSigner } from "@ethersproject/abstract-signer";

export interface AuctionFee {
    isMatcher: boolean; recipient: string; amountOrRate: BigNumberish; feeData: BytesLike
}

export interface Fee {
    recipient: string; amount: BigNumberish; feeData: BytesLike
}

export function b(amount: bigint | string | number): BigNumber {
    return utils.parseEther(amount.toString());
}

export interface ERC721Order {
    direction: BigNumberish;
    maker: string;
    taker: string;
    expiry: BigNumberish;
    nonce: BigNumberish;
    erc20Token: string;
    erc20TokenAmount: BigNumberish;
    fees: Fee[];
    erc721Token: string;
    erc721TokenId: BigNumberish;
    erc721TokenProperties: {
        propertyValidator: string;
        propertyData: BytesLike;
    }[];
}

export async function signTypedDataERC721(chainID: number, maker: Signer, dex: IZeroEx, order: ERC721Order) {
    const ERC721ORDER_STRUCT_NAME = 'ERC721Order';

    const domain = {
        chainId: chainID,
        verifyingContract: dex.address,
        name: '721FM',
        version: '1.0.0',
    };

    const types = {
        [ERC721ORDER_STRUCT_NAME]: ERC721ORDER_STRUCT_ABI,
        Fee: FEE_ABI,
        Property: PROPERTY_ABI,
    };

    const signature = await (maker as unknown as TypedDataSigner)._signTypedData(domain, types, order);
    const sig = utils.splitSignature(signature);
    return {
        signatureType: SignatureType.EIP712,
        v: sig.v,
        r: sig.r,
        s: sig.s,
    };
}

export interface ERC1155Order {
    direction: BigNumberish;
    maker: string;
    taker: string;
    expiry: BigNumberish;
    nonce: BigNumberish;
    erc20Token: string;
    erc20TokenAmount: BigNumberish;
    fees: Fee[];
    erc1155Token: string;
    erc1155TokenId: BigNumberish;
    erc1155TokenProperties: {
        propertyValidator: string;
        propertyData: BytesLike;
    }[];
    erc1155TokenAmount: BigNumberish;
}

export async function signTypedDataERC1155(chainID: number, maker: Signer, dex: IZeroEx, order: ERC1155Order) {
    const ERC1155ORDER_STRUCT_NAME = 'ERC1155Order';

    const domain = {
        chainId: chainID,
        verifyingContract: dex.address,
        name: '721FM',
        version: '1.0.0',
    };

    const types = {
        [ERC1155ORDER_STRUCT_NAME]: ERC1155ORDER_STRUCT_ABI,
        Fee: FEE_ABI,
        Property: PROPERTY_ABI,
    };

    const signature = await (maker as unknown as TypedDataSigner)._signTypedData(domain, types, order);
    const sig = utils.splitSignature(signature);
    return {
        signatureType: SignatureType.EIP712,
        v: sig.v,
        r: sig.r,
        s: sig.s,
    };
}

export function convertToSolidityStyle(abi: Array<{ type: string, name: string }>) {
    const result = [];
    for (const item of abi) {
        if (item.type.includes("AuctionFee")) {
            const a = convertToSolidityStyle(AUCTION_FEE_ABI);
            const newType: string = item.type.replace("AuctionFee", a);
            result.push(`${newType} ${item.name}`);
        } else if (item.type.includes("Fee")) {
            const a = convertToSolidityStyle(FEE_ABI);
            const newType: string = item.type.replace("Fee", a);
            result.push(`${newType} ${item.name}`);
        } else if (item.type.includes("Property")) {
            const a = convertToSolidityStyle(PROPERTY_ABI);
            const newType: string = item.type.replace("Property", a);
            result.push(`${newType} ${item.name}`);
        } else if (item.type.includes("NFTAuction")) {
            const a = convertToSolidityStyle(AUCTION_STRUCT_ABI);
            const newType: string = item.type.replace("NFTAuction", a);
            result.push(`${newType} ${item.name}`);
        } /* else if (item.type.includes("EnglishAuctionBid")) {
            const a = convertToSolidityStyle(EN_AUCTION_BID_STRUCT_ABI);
            const newType: string = item.type.replace("EnglishAuctionBid", a);
            result.push(`${newType} ${item.name}`);
        } */ else {
            result.push(`${item.type} ${item.name}`);
        }
    }
    return `tuple(${result.join(",")})`;
}

export interface NFTAuction {
    auctionKind: BigNumberish;
    nftKind: BigNumberish;
    earlyMatch: boolean;
    maker: string;
    startTime: BigNumberish;
    endTime: BigNumberish;
    nonce: BigNumberish;
    erc20Token: string;
    startErc20TokenAmount: BigNumberish;
    endOrReservedErc20TokenAmount: BigNumberish;
    fees: AuctionFee[];
    nftToken: string;
    nftTokenId: BigNumberish;
    nftTokenAmount: BigNumberish;
}

export interface Signature {
    signatureType: BigNumberish;
    v: BigNumberish;
    r: BytesLike;
    s: BytesLike;
}

export async function signTypedDataAuction(chainID: number, maker: Signer, dex: IZeroEx, auction: NFTAuction) {
    const STRUCT_NAME = 'NFTAuction';

    const domain = {
        chainId: chainID,
        verifyingContract: dex.address,
        name: '721FM',
        version: '1.0.0',
    };

    const types = {
        [STRUCT_NAME]: AUCTION_STRUCT_ABI,
        AuctionFee: AUCTION_FEE_ABI,
    };

    const signature = await (maker as unknown as TypedDataSigner)._signTypedData(domain, types, auction);
    const sig = utils.splitSignature(signature);
    return {
        signatureType: SignatureType.EIP712,
        v: sig.v,
        r: sig.r,
        s: sig.s,
    };
}

export interface EnglishAuctionBid {
    auction: NFTAuction;
    bidMaker: string;
    erc20TokenAmount: BigNumberish;
}

export async function signTypedDataEnAuctionBid(chainID: number, maker: Signer, dex: IZeroEx, bid: EnglishAuctionBid) {
    const STRUCT_NAME = 'EnglishAuctionBid';

    const domain = {
        chainId: chainID,
        verifyingContract: dex.address,
        name: '721FM',
        version: '1.0.0',
    };

    const types = {
        [STRUCT_NAME]: EN_AUCTION_BID_STRUCT_ABI,
        NFTAuction: AUCTION_STRUCT_ABI,
        AuctionFee: AUCTION_FEE_ABI,
    };

    const signature = await (maker as unknown as TypedDataSigner)._signTypedData(domain, types, bid);
    const sig = utils.splitSignature(signature);
    return {
        signatureType: SignatureType.EIP712,
        v: sig.v,
        r: sig.r,
        s: sig.s,
    };
}

// ------------------- price calc ----------------------

export function erc1155BuyOrderPrice(order: ERC1155Order, amount: BigNumberish) {
    return priceWithFee(
        BigNumber.from(order.erc20TokenAmount),
        amount,
        order.fees,
        BigNumber.from(order.erc1155TokenAmount),
        false,
    );
}

export function erc1155SellOrderPrice(order: ERC1155Order, amount: BigNumberish) {
    return priceWithFee(
        BigNumber.from(order.erc20TokenAmount),
        amount,
        order.fees,
        BigNumber.from(order.erc1155TokenAmount),
        true,
    );
}

function priceWithFee(priceCost: BigNumber, bidAmount: BigNumberish, fees: Fee[], nftTokenAmount: BigNumber, ceil: boolean) {
    bidAmount = BigNumber.from(bidAmount);
    const feeCostList = [];
    for (const fee of fees) {
        const bFee = BigNumber.from(fee.amount);
        if (!bFee.isZero()) {
            feeCostList.push(getPartialAmountFloor(
                bidAmount,
                nftTokenAmount,
                bFee,
            ));
        }
    }
    if (!bidAmount.eq(nftTokenAmount)) {
        if (ceil) {
            priceCost = getPartialAmountCeil(
                bidAmount,
                nftTokenAmount,
                priceCost,
            );
        } else {
            priceCost = getPartialAmountFloor(
                bidAmount,
                nftTokenAmount,
                priceCost,
            );
        }
    }
    const feeCost = feeCostList.reduce((p, c) => p.add(c), BigNumber.from(0));
    return {
        priceCost,
        feeCost,
        totalCost: priceCost.add(feeCost),
        feeCostList,
        nftTokenAmount,
        amount: BigNumber.from(bidAmount),
    };
}

export function auctionPriceWithFee(auction: NFTAuction, bidAmount: BigNumberish, bidTime: BigNumberish) {
    return dutchAuctionPriceWithFee(
        auctionPrice(auction, bidTime),
        bidAmount,
        auction,
        BigNumber.from(auction.nftTokenAmount),
    );
}

export type TradeCost = ReturnType<typeof priceWithFee>;

function dutchAuctionPriceWithFee(priceCost: BigNumber, bidAmount: BigNumberish, auction: NFTAuction, nftTokenAmount: BigNumber) {
    bidAmount = BigNumber.from(bidAmount);
    if (!bidAmount.eq(nftTokenAmount)) {
        priceCost = getPartialAmountCeil(
            bidAmount,
            nftTokenAmount,
            priceCost,
        );
    }

    const feeCostList = [];
    for (const fee of auction.fees) {
        const bFee = BigNumber.from(fee.amountOrRate); // as amount
        if (!bFee.isZero()) {
            feeCostList.push(getPartialAmountFloor(
                priceCost,
                BigNumber.from(auction.startErc20TokenAmount),
                bFee,
            ));
        }
    }

    const feeCost = feeCostList.reduce((p, c) => p.add(c), BigNumber.from(0));
    return {
        priceCost,
        feeCost,
        totalCost: priceCost.add(feeCost),
        feeCostList,
        nftTokenAmount,
        amount: BigNumber.from(bidAmount),
    };
}

export function enAuctionPrice(enBid: EnglishAuctionBid) {
    const erc20TokenAmount = BigNumber.from(enBid.erc20TokenAmount);
    let totalPrice = BigNumber.from(enBid.erc20TokenAmount);
    let maxMatcherPrice = BigNumber.from(0);
    const fees = [];
    for (const fee of enBid.auction.fees) {
        const bFee = getPartialAmountFloor(
            BigNumber.from(fee.amountOrRate), // as rate
            utils.parseEther("1"),
            erc20TokenAmount,
        );
        fees.push(bFee);
        if (fee.isMatcher) {
            if (bFee.gt(maxMatcherPrice)) {
                maxMatcherPrice = bFee;
            }
        } else {
            totalPrice = totalPrice.add(bFee);
        }
    }
    return {
        amount: totalPrice.add(maxMatcherPrice),
        fees,
    }
}

function auctionPrice(auction: NFTAuction, bidTime: BigNumberish): BigNumber {
    bidTime = BigNumber.from(bidTime);
    const startTime = BigNumber.from(auction.startTime);
    const endTime = BigNumber.from(auction.endTime);
    const startAmount = BigNumber.from(auction.startErc20TokenAmount);
    const endAmount = BigNumber.from(auction.endOrReservedErc20TokenAmount);

    if (bidTime.lt(startTime)) {
        bidTime = startTime;
    } else if (bidTime.gt(endTime)) {
        bidTime = endTime;
    }

    return getPartialAmountCeil(
        endTime.sub(bidTime),
        endTime.sub(startTime),
        startAmount.sub(endAmount),
    ).add(endAmount);
}

function getPartialAmountCeil(
    numerator: BigNumber,
    denominator: BigNumber,
    target: BigNumber,
): BigNumber {
    // safeDiv computes `floor(a / b)`. We use the identity (a, b integer):
    //       ceil(a / b) = floor((a + b - 1) / b)
    // To implement `ceil(a / b)` using safeDiv.
    return numerator.mul(target).add(denominator.sub(1)).div(denominator);
}

function getPartialAmountFloor(
    numerator: BigNumber,
    denominator: BigNumber,
    target: BigNumber,
): BigNumber {
    return numerator.mul(target).div(denominator);
}
