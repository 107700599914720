/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type {
  MultiplexUniswapV2,
  MultiplexUniswapV2Interface,
} from "../MultiplexUniswapV2";

const _abi = [
  {
    inputs: [
      {
        components: [
          {
            internalType: "contract IERC20TokenV06",
            name: "inputToken",
            type: "address",
          },
          {
            internalType: "contract IERC20TokenV06",
            name: "outputToken",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "sellAmount",
            type: "uint256",
          },
          {
            components: [
              {
                internalType: "enum IMultiplexFeature.MultiplexSubcall",
                name: "id",
                type: "uint8",
              },
              {
                internalType: "uint256",
                name: "sellAmount",
                type: "uint256",
              },
              {
                internalType: "bytes",
                name: "data",
                type: "bytes",
              },
            ],
            internalType: "struct IMultiplexFeature.BatchSellSubcall[]",
            name: "calls",
            type: "tuple[]",
          },
          {
            internalType: "bool",
            name: "useSelfBalance",
            type: "bool",
          },
          {
            internalType: "address",
            name: "recipient",
            type: "address",
          },
        ],
        internalType: "struct IMultiplexFeature.BatchSellParams",
        name: "params",
        type: "tuple",
      },
      {
        internalType: "bytes",
        name: "wrappedCallData",
        type: "bytes",
      },
      {
        internalType: "uint256",
        name: "sellAmount",
        type: "uint256",
      },
    ],
    name: "_batchSellUniswapV2External",
    outputs: [
      {
        internalType: "uint256",
        name: "boughtAmount",
        type: "uint256",
      },
    ],
    stateMutability: "payable",
    type: "function",
  },
];

export class MultiplexUniswapV2__factory {
  static readonly abi = _abi;
  static createInterface(): MultiplexUniswapV2Interface {
    return new utils.Interface(_abi) as MultiplexUniswapV2Interface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): MultiplexUniswapV2 {
    return new Contract(address, _abi, signerOrProvider) as MultiplexUniswapV2;
  }
}
