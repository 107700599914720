/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type {
  ILiquidityProvider,
  ILiquidityProviderInterface,
} from "../ILiquidityProvider";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "contract IERC20TokenV06",
        name: "inputToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "contract IERC20TokenV06",
        name: "outputToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "inputTokenAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "outputTokenAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "bytes32",
        name: "sourceId",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "address",
        name: "sourceAddress",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "sender",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "recipient",
        type: "address",
      },
    ],
    name: "LiquidityProviderFill",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "contract IERC20TokenV06",
        name: "inputToken",
        type: "address",
      },
      {
        internalType: "contract IERC20TokenV06",
        name: "outputToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "sellAmount",
        type: "uint256",
      },
    ],
    name: "getSellQuote",
    outputs: [
      {
        internalType: "uint256",
        name: "outputTokenAmount",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract IERC20TokenV06",
        name: "outputToken",
        type: "address",
      },
      {
        internalType: "address",
        name: "recipient",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "minBuyAmount",
        type: "uint256",
      },
      {
        internalType: "bytes",
        name: "auxiliaryData",
        type: "bytes",
      },
    ],
    name: "sellEthForToken",
    outputs: [
      {
        internalType: "uint256",
        name: "boughtAmount",
        type: "uint256",
      },
    ],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract IERC20TokenV06",
        name: "inputToken",
        type: "address",
      },
      {
        internalType: "address payable",
        name: "recipient",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "minBuyAmount",
        type: "uint256",
      },
      {
        internalType: "bytes",
        name: "auxiliaryData",
        type: "bytes",
      },
    ],
    name: "sellTokenForEth",
    outputs: [
      {
        internalType: "uint256",
        name: "boughtAmount",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract IERC20TokenV06",
        name: "inputToken",
        type: "address",
      },
      {
        internalType: "contract IERC20TokenV06",
        name: "outputToken",
        type: "address",
      },
      {
        internalType: "address",
        name: "recipient",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "minBuyAmount",
        type: "uint256",
      },
      {
        internalType: "bytes",
        name: "auxiliaryData",
        type: "bytes",
      },
    ],
    name: "sellTokenForToken",
    outputs: [
      {
        internalType: "uint256",
        name: "boughtAmount",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export class ILiquidityProvider__factory {
  static readonly abi = _abi;
  static createInterface(): ILiquidityProviderInterface {
    return new utils.Interface(_abi) as ILiquidityProviderInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): ILiquidityProvider {
    return new Contract(address, _abi, signerOrProvider) as ILiquidityProvider;
  }
}
