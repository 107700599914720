import { BigNumber, ethers } from 'ethers';
import { arrayify } from 'ethers/lib/utils';
import { useState } from 'react';
import { useAppSelector } from '../../../app/hooks';
import styles from '../Assets.module.css';
import { ShowPrice } from '../Components';
import {
  getWETHAddress,
  getZEROEXAddress
} from '../contracts';
import {
  b, convertToSolidityStyle, erc1155BuyOrderPrice, TradeCost,
  ERC721Order,
  signTypedDataERC721,
  Signature,
  ERC1155Order,
  signTypedDataERC1155
} from '../dex-utils';
import { selectNetwork, selectProvider } from '../dexSlice';
import {
  IZeroEx__factory, MockERC1155__factory,
  MockERC721__factory
} from '../typechain';
import {
  CODE_ERC1155,
  CODE_ERC721,
  DAY,
  ERC1155ORDER_STRUCT_ABI, ERC721ORDER_STRUCT_ABI,
  NULL_ADDRESS, SIGNATURE_ABI, timestampInSecond, TradeDirection
} from '../utils';

export function Offer(_props: any) {
  const [listing, setListing] = useState<string>();
  const [tokenID, setTokenID] = useState<string>("1");
  const [tokenID1155, setTokenID1155] = useState<string>("1");
  const [payToken, setPayToken] = useState<string>(getWETHAddress());
  const [feeAddress, setFeeAddress] = useState<string>(NULL_ADDRESS);
  const network = useAppSelector(selectNetwork);

  const provider = useAppSelector(selectProvider);
  const maker = provider.getSigner();
  const taker = provider.getSigner();
  const dex = IZeroEx__factory.connect(getZEROEXAddress(), provider);
  const azuki = MockERC721__factory.connect("0x9B90f643dA4bC61386B431Cd0877acA9f834284d", provider);
  const opendao = MockERC1155__factory.connect("0xfbDcF4A63A910B720f8cfe9390E9543B0D3d9275", provider);
  let sec = timestampInSecond();

  async function list721() {
    const order: ERC721Order = {
      direction: TradeDirection.BUY_NFT, // OFFER
      maker: await maker.getAddress(),
      taker: NULL_ADDRESS,
      expiry: sec + DAY,
      nonce: ++sec,
      erc20Token: payToken,
      erc20TokenAmount: b("0.02"),
      fees: [{
        recipient: feeAddress,
        amount: b("0.01"),
        feeData: "0x",
      }].filter(e => e.recipient !== NULL_ADDRESS),
      erc721Token: azuki.address,
      erc721TokenId: tokenID,
      erc721TokenProperties: [],
    };
    const signature: Signature = await signTypedDataERC721(network?.chainId || 0, maker, dex, order);
    const listing = { order, signature };
    setListing(JSON.stringify(listing));
  }

  async function sell721() {
    if (!listing) return;
    const _listing = JSON.parse(listing);
    await dex.connect(taker).sellERC721(
      _listing.order,
      _listing.signature,
      _listing.order.erc721TokenId,
      true,
      arrayify("0x"),
    );
  }

  async function sell721NotRequireApproval() {
    if (!listing) return;
    const _listing = JSON.parse(listing);
    const takerAddr = await taker.getAddress();
    const data = ethers.utils.defaultAbiCoder.encode([
      "uint256",
      convertToSolidityStyle(ERC721ORDER_STRUCT_ABI),
      convertToSolidityStyle(SIGNATURE_ABI),
      "bool",
    ], [CODE_ERC721, _listing.order, _listing.signature, true]);
    await azuki.connect(taker)['safeTransferFrom(address,address,uint256,bytes)'](takerAddr, dex.address, _listing.order.erc721TokenId, data);
  }

  async function cancel721() {
    if (!listing) return;
    const _listing: { order: ERC721Order, signature: Signature } = JSON.parse(listing);
    // await dex.connect(taker).cancelERC721Order(_listing.order.nonce);
    await dex.connect(taker).batchCancelERC721Orders([_listing.order.nonce]);
  }

  const [prices, setPrices] = useState<TradeCost[]>();
  async function list1155() {
    const erc1155TokenAmount = BigNumber.from(6);
    const order: ERC1155Order = {
      direction: TradeDirection.BUY_NFT, // OFFER
      maker: await maker.getAddress(),
      taker: NULL_ADDRESS,
      expiry: sec + DAY,
      nonce: ++sec,
      erc20Token: payToken,
      erc20TokenAmount: b("0.02"),
      fees: [{
        recipient: feeAddress,
        amount: b("0.01"),
        feeData: "0x",
      }].filter(e => e.recipient !== NULL_ADDRESS),
      erc1155Token: opendao.address,
      erc1155TokenId: tokenID1155,
      erc1155TokenAmount,
      erc1155TokenProperties: [],
    };
    const signature: Signature = await signTypedDataERC1155(network?.chainId || 0, maker, dex, order);
    const listing = { order, signature };
    setListing(JSON.stringify(listing));
    setPrices([
      erc1155BuyOrderPrice(listing.order, erc1155TokenAmount.div(3)),
      erc1155BuyOrderPrice(listing.order, erc1155TokenAmount.div(2)),
      erc1155BuyOrderPrice(listing.order, erc1155TokenAmount),
    ]);
  }

  async function sell1155() {
    if (!listing) return;
    const _listing: { order: ERC1155Order, signature: Signature } = JSON.parse(listing);
    await dex.connect(taker).sellERC1155(
      _listing.order,
      _listing.signature,
      _listing.order.erc1155TokenId,
      2,
      true,
      arrayify("0x"),
    );
  }

  async function sell1155NotRequireApproval() {
    if (!listing) return;
    const _listing: { order: ERC1155Order, signature: Signature } = JSON.parse(listing);
    const takerAddr = await taker.getAddress();
    const data = ethers.utils.defaultAbiCoder.encode([
      "uint256",
      convertToSolidityStyle(ERC1155ORDER_STRUCT_ABI),
      convertToSolidityStyle(SIGNATURE_ABI),
      "bool"
    ], [CODE_ERC1155, _listing.order, _listing.signature, true]);
    await opendao.connect(taker).safeTransferFrom(takerAddr, dex.address, _listing.order.erc1155TokenId, 2, data);
  }

  async function cancel1155() {
    if (!listing) return;
    const _listing: { order: ERC1155Order, signature: Signature } = JSON.parse(listing);
    // await dex.connect(taker).cancelERC1155Order(_listing.order.nonce);
    await dex.connect(taker).batchCancelERC1155Orders([_listing.order.nonce]);
  }

  return (
    <div className={styles.border}>
      <h2>Offers</h2>
      <label>erc721 token ids:</label> <input value={tokenID} onChange={(e) => setTokenID(e.target.value)}></input><br></br>
      <label>erc1155 token ids:</label> <input value={tokenID1155} onChange={(e) => setTokenID1155(e.target.value)}></input><br></br>
      <label>pay token:</label> <input value={payToken} onChange={(e) => setPayToken(e.target.value)} size={40}></input><br></br>
      <label>fee address:</label> <input value={feeAddress} onChange={(e) => setFeeAddress(e.target.value)} size={40}></input><br></br>
      <label>listing:</label> <input value={listing} onChange={(e) => setListing(e.target.value)} size={40}></input><br></br>
      <button onClick={list721}>Make a erc721 offer</button>
      <button onClick={sell721}>Take the erc721 offer</button>
      <button onClick={sell721NotRequireApproval}>Take the erc721 offer without approve</button>
      <button onClick={cancel721}>Cancel erc721 offer</button><br></br>
      <button onClick={list1155}>Make a erc1155 offer</button>
      <button onClick={sell1155}>Take the erc1155 offer</button>
      <button onClick={sell1155NotRequireApproval}>Take the erc1155 offer without approve</button>
      <button onClick={cancel1155}>Cancel erc11155 offer</button><br></br>
      <ShowPrice costs={prices}></ShowPrice>
    </div>
  );
}
