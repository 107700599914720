import { IZeroEx__factory, MockERC1155__factory, MockERC721__factory } from "../typechain";
import React, { useState } from 'react';
import { useAppSelector } from '../../../app/hooks';
import { selectNetwork, selectProvider } from "../dexSlice";
import styles from '../Assets.module.css';
import {
  getWETHAddress,
  getZEROEXAddress
} from "../contracts";
import {
  CODE_AUCTION,
  EN_AUCTION_BID_STRUCT_ABI,
  MINUTE,
  NFTAuctionKind, NFTKind, NULL_ADDRESS,
  SIGNATURE_ABI,
  timestampInSecond
} from "../utils";
import {
  b, NFTAuction, Signature, enAuctionPrice, convertToSolidityStyle,
  signTypedDataAuction, EnglishAuctionBid, signTypedDataEnAuctionBid
} from "../dex-utils";
import { BigNumber, utils } from "ethers";

export function EnglishAuction(_props: any) {
  const [auction, setAuction] = useState<string>();
  const [bid, setBid] = useState<string>();

  const [amount, setAmount] = useState<string>();
  const [tokenID, setTokenID] = useState<string>("0");
  const [tokenID1155, setTokenID1155] = useState<string>("0");
  const [payToken, setPayToken] = useState<string>(getWETHAddress());
  const [feeAddress, setFeeAddress] = useState<string>(NULL_ADDRESS);
  const network = useAppSelector(selectNetwork);

  const provider = useAppSelector(selectProvider);
  const maker = provider.getSigner();
  const taker = provider.getSigner();

  const dex = IZeroEx__factory.connect(getZEROEXAddress(), provider);
  const azuki = MockERC721__factory.connect("0x9B90f643dA4bC61386B431Cd0877acA9f834284d", provider);
  const opendao = MockERC1155__factory.connect("0xfbDcF4A63A910B720f8cfe9390E9543B0D3d9275", provider);

  async function list721Auction() {
    const time = (await provider.getBlock("latest")).timestamp;
    const auction: NFTAuction = {
      auctionKind: NFTAuctionKind.ENGLISH,
      nftKind: NFTKind.ERC721,
      maker: await maker.getAddress(),
      startTime: time,
      endTime: time + 10 * MINUTE,
      nonce: timestampInSecond(),
      erc20Token: payToken,
      startErc20TokenAmount: b("0.01"),
      endOrReservedErc20TokenAmount: b("0.02"),
      fees: [{
        isMatcher: false, // Whether the auction can be matched by Bots.
        recipient: feeAddress,
        amountOrRate: b("0.005"), // treated as rate(0.5%) in English Auction
        feeData: "0x",
      }].filter(e => e.recipient !== NULL_ADDRESS),
      nftToken: azuki.address,
      nftTokenId: BigNumber.from(tokenID),
      nftTokenAmount: 1, // always be one in ERC721 Auction.
      earlyMatch: true, // Whether a bid can be taken before endTime.
    };
    const signature: Signature = await signTypedDataAuction(network?.chainId || 0, maker, dex, auction);
    const listing = { auction, signature };
    setAuction(JSON.stringify(listing));
  }

  async function bid721Auction() {
    if (!auction) return;
    const _listing: { auction: NFTAuction, signature: Signature } = JSON.parse(auction);
    const bid: EnglishAuctionBid = {
      auction: _listing.auction,
      bidMaker: await maker.getAddress(),
      erc20TokenAmount: b("0.025"),
    };
    const { amount } = enAuctionPrice(bid);
    setAmount(amount.toString());
    const bidSig: Signature = await signTypedDataEnAuctionBid(network?.chainId || 0, maker, dex, bid);
    const listing = { bid, auctionSig: _listing.signature, bidSig };
    setBid(JSON.stringify(listing));
  }

  async function take721Auction() {
    if (!bid) return;
    const _bid: { bid: EnglishAuctionBid, auctionSig: Signature, bidSig: Signature } = JSON.parse(bid);

    await dex.connect(taker).acceptBid(
      _bid.bid,
      _bid.auctionSig,
      _bid.bidSig,
      _bid.bid.auction.erc20Token === getWETHAddress() ? true : false,
      "0x"
    );
  }

  async function take721AuctionWithoutApprove() {
    if (!bid) return;
    const _bid: { bid: EnglishAuctionBid, auctionSig: Signature, bidSig: Signature } = JSON.parse(bid);
    const data = utils.defaultAbiCoder.encode([
      "uint256",
      convertToSolidityStyle(EN_AUCTION_BID_STRUCT_ABI),
      convertToSolidityStyle(SIGNATURE_ABI),
      convertToSolidityStyle(SIGNATURE_ABI),
      "bool"
    ], [
      CODE_AUCTION,
      _bid.bid,
      _bid.auctionSig,
      _bid.bidSig,
      _bid.bid.auction.erc20Token === getWETHAddress() ? true : false,
    ]);
    await azuki.connect(taker)["safeTransferFrom(address,address,uint256,bytes)"](
      await taker.getAddress(), dex.address, _bid.bid.auction.nftTokenId, data,
    );
  }

  async function cancel721() {
    if (!auction) return;
    const _listing: { auction: NFTAuction, signature: Signature } = JSON.parse(auction);
    // await dex.connect(taker).cancelAuction(_listing.auction.nonce);
    await dex.connect(taker).batchCancelAuctions([_listing.auction.nonce]);
  }

  async function list1155Auction() {
    const time = (await provider.getBlock("latest")).timestamp;
    const auction: NFTAuction = {
      auctionKind: NFTAuctionKind.ENGLISH,
      nftKind: NFTKind.ERC1155,
      maker: await maker.getAddress(),
      startTime: time,
      endTime: time + 10 * MINUTE,
      nonce: timestampInSecond(),
      erc20Token: payToken,
      startErc20TokenAmount: b("0.01"),
      endOrReservedErc20TokenAmount: b("0.02"),
      fees: [{
        isMatcher: false, // Whether the auction can be matched by Bots.
        recipient: feeAddress,
        amountOrRate: b("0.005"), // treated as rate(0.5%) in English Auction
        feeData: "0x",
      }].filter(e => e.recipient !== NULL_ADDRESS),
      nftToken: opendao.address,
      nftTokenId: BigNumber.from(tokenID),
      nftTokenAmount: 5, // always be one in ERC721 Auction.
      earlyMatch: true, // Whether a bid can be taken before endTime.
    };
    const signature: Signature = await signTypedDataAuction(network?.chainId || 0, maker, dex, auction);
    const listing = { auction, signature };
    setAuction(JSON.stringify(listing));
  }

  async function bid1155Auction() {
    await bid721Auction();
  }

  async function take1155Auction() {
    await take721Auction();
  }

  async function take1155AuctionWithoutApprove() {
    if (!bid) return;
    const _bid: { bid: EnglishAuctionBid, auctionSig: Signature, bidSig: Signature } = JSON.parse(bid);
    const data = utils.defaultAbiCoder.encode([
      "uint256",
      convertToSolidityStyle(EN_AUCTION_BID_STRUCT_ABI),
      convertToSolidityStyle(SIGNATURE_ABI),
      convertToSolidityStyle(SIGNATURE_ABI),
      "bool"
    ], [
      CODE_AUCTION,
      _bid.bid,
      _bid.auctionSig,
      _bid.bidSig,
      _bid.bid.auction.erc20Token === getWETHAddress() ? true : false,
    ]);
    await opendao.connect(taker).safeTransferFrom(
      await taker.getAddress(),
      dex.address,
      _bid.bid.auction.nftTokenId,
      _bid.bid.auction.nftTokenAmount,
      data,
    );
  }

  async function cancel1155() {
    await cancel721();
  }

  return (
    <div className={styles.border}>
      <h2>English Auction</h2>
      <label>erc721 token id:</label> <input value={tokenID} onChange={(e) => setTokenID(e.target.value)}></input><br></br>
      <label>erc1155 token id:</label> <input value={tokenID1155} onChange={(e) => setTokenID1155(e.target.value)}></input><br></br>
      <label>pay token:</label> <input value={payToken} onChange={(e) => setPayToken(e.target.value)} size={40}></input><br></br>
      <label>fee address:</label> <input value={feeAddress} onChange={(e) => setFeeAddress(e.target.value)} size={40}></input><br></br>
      <label>auction:</label> <input value={auction} onChange={(e) => setAuction(e.target.value)} size={40}></input><br></br>
      <label>bid:</label> <input value={bid} onChange={(e) => setBid(e.target.value)} size={40}></input><br></br>
      <button onClick={list721Auction}>List erc721 English auction</button>
      <button onClick={bid721Auction}>Bid erc721 English auction</button>
      <button onClick={take721Auction}>Take erc721 English auction</button>
      <button onClick={take721AuctionWithoutApprove}>Take erc721 English auction without approve</button>
      <button onClick={cancel721}>Cancel erc721 EnglishAuction</button><br></br>

      <button onClick={list1155Auction}>List erc1155 English auction</button>
      <button onClick={bid1155Auction}>Bid erc1155 English auction</button>
      <button onClick={take1155Auction}>Take erc1155 English auction</button>
      <button onClick={take1155AuctionWithoutApprove}>Take erc1155 English auction without approve</button>
      <button onClick={cancel1155}>Cancel erc1155 EnglishAuction</button><br></br>
      <p>buy maker will pay: {amount}</p>
    </div>
  );
}
