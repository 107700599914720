import { ethers, utils } from 'ethers';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../app/hooks';
import styles from '../Assets.module.css';
import { getERC20Address, getWETHAddress, getZEROEXAddress } from '../contracts';
import { selectNetwork, selectProvider } from '../dexSlice';
import { IZeroEx__factory, MockERC1155__factory, MockERC20__factory, MockERC721__factory, MockWETH__factory } from '../typechain';
import { UINT256_MAX } from '../utils';

export function TransferNFTs(_props: any) {
    const defaultAddr = "0x93140929fBf04c361E10F13bc913F1AF15DfD11A";
    const [toAddr, setToAddr] = useState<string>(defaultAddr);
    const [toAddrMsg, setToAddrMsg] = useState<string>("");
    const [txMsg, setTxMsg] = useState<string>("");

    const provider = useAppSelector(selectProvider);
    const network = useAppSelector(selectNetwork);
    const account = provider.getSigner();
    const erc20 = MockERC20__factory.connect(getERC20Address(network), provider);
    const azuki = MockERC721__factory.connect("0x9B90f643dA4bC61386B431Cd0877acA9f834284d", provider);
    const opendao = MockERC1155__factory.connect("0xfbDcF4A63A910B720f8cfe9390E9543B0D3d9275", provider);
    const dex = IZeroEx__factory.connect(getZEROEXAddress(network), provider);
    const WETH = MockWETH__factory.connect(getWETHAddress(network), provider);

    async function ensureApproved() {
        let nonce = await account.getTransactionCount();
        const accountAddr = await account.getAddress();
        if (!await azuki.isApprovedForAll(accountAddr, dex.address)) {
            await azuki.connect(account).setApprovalForAll(dex.address, true, {
                maxFeePerGas: utils.parseUnits("200", "gwei"),
                maxPriorityFeePerGas: utils.parseUnits("1.5", "gwei"),
                nonce: nonce++,
            });
        }
        if (!await opendao.isApprovedForAll(accountAddr, dex.address)) {
            await opendao.connect(account).setApprovalForAll(dex.address, true, {
                maxFeePerGas: utils.parseUnits("200", "gwei"),
                maxPriorityFeePerGas: utils.parseUnits("1.5", "gwei"),
                nonce: nonce++,
            });
        }
        if ((await WETH.allowance(accountAddr, dex.address)).lt(utils.parseEther("1000"))) {
            await WETH.connect(account).approve(dex.address, UINT256_MAX, {
                maxFeePerGas: utils.parseUnits("200", "gwei"),
                maxPriorityFeePerGas: utils.parseUnits("1.5", "gwei"),
                nonce: nonce++,
            });
        }
        if ((await erc20.allowance(accountAddr, dex.address)).lt(utils.parseEther("1000"))) {
            await erc20.connect(account).approve(dex.address, UINT256_MAX, {
                maxFeePerGas: utils.parseUnits("200", "gwei"),
                maxPriorityFeePerGas: utils.parseUnits("1.5", "gwei"),
                nonce: nonce++,
            });
        }
    }

    useEffect(() => {
        if (!ethers.utils.isAddress(toAddr)) {
            setToAddrMsg("Error");
            setToAddr(toAddr.trim());
        } else {
            setToAddrMsg("");
        }
    }, [toAddr]);

    async function transfer() {
        const accountAddr = await account.getAddress();
        const count = (await azuki.balanceOf(accountAddr)).toNumber();
        const tokens = [];
        for (let i = 0; i < count; ++i) {
            const tokenId = await azuki.tokenOfOwnerByIndex(accountAddr, i);
            tokens.push({
                token: azuki.address,
                tokenId,
                to: toAddr,
            })
        }
        const balances = await opendao.balanceOfBatch(
            [accountAddr, accountAddr, accountAddr],
            [0, 1, 2],
        );
        const tokens1155 = balances.map((amount, tokenId) => {
            return {
                token: opendao.address,
                tokenId,
                to: toAddr,
                amount,
            }
        });
        const tx = await dex.connect(account).batchTransferNFTAssetsFrom(tokens, tokens1155, {
            maxFeePerGas: utils.parseUnits("200", "gwei"),
            maxPriorityFeePerGas: utils.parseUnits("1.5", "gwei"),
        });
        setTxMsg(`https://${network?.name}.etherscan.io/tx/${tx.hash}`);
    }

    return (
        <div className={styles.border}>
            <h2>Batch Transfer</h2>
            <button onClick={ensureApproved}>Ensure Approved</button><br></br>
            <label>transfer to:</label>
            <input value={toAddr} size={40} onChange={(e) => { setToAddr(e.target.value) }}></input>
            <label>{toAddrMsg}</label>
            <br></br>
            <button onClick={transfer}>Transfer</button><br></br>
            <a href={txMsg}>{txMsg}</a>
        </div>
    );
}
