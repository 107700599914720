/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import { Provider, TransactionRequest } from "@ethersproject/providers";
import type { MixinLido, MixinLidoInterface } from "../MixinLido";

const _abi = [
  {
    inputs: [
      {
        internalType: "contract IEtherTokenV06",
        name: "weth",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
];

const _bytecode =
  "0x60a060405234801561001057600080fd5b5060405161018d38038061018d83398181016040528101906100329190610108565b6100647f7c2ad8be048cad79fb10d041588ba8d3c2f4003ea8c5b0da9ed3450c8bfd8e6e60001b61010560201b60201c565b6100967fadf3b0c43c76f151271da46c3c0ac947ac36a1ed2b9c30eda139018bf9c0a20e60001b61010560201b60201c565b6100c87ff806ac9ab34e3c7c5d77b7da7a22307eacef27419d980e31ba7198b8f2127d0c60001b61010560201b60201c565b8073ffffffffffffffffffffffffffffffffffffffff1660808173ffffffffffffffffffffffffffffffffffffffff1660601b8152505050610138565b50565b600060208284031215610119578081fd5b815160018060a01b038116811461012e578182fd5b8091505092915050565b60805160601c603f61014e60003950603f6000f3fe6080604052600080fdfea26469706673582212202c535a5c20da8c462849e27f48947b365921fe6b359c65c2bea5c89a7dcd380564736f6c634300060c0033";

export class MixinLido__factory extends ContractFactory {
  constructor(
    ...args: [signer: Signer] | ConstructorParameters<typeof ContractFactory>
  ) {
    if (args.length === 1) {
      super(_abi, _bytecode, args[0]);
    } else {
      super(...args);
    }
  }

  deploy(
    weth: string,
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<MixinLido> {
    return super.deploy(weth, overrides || {}) as Promise<MixinLido>;
  }
  getDeployTransaction(
    weth: string,
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(weth, overrides || {});
  }
  attach(address: string): MixinLido {
    return super.attach(address) as MixinLido;
  }
  connect(signer: Signer): MixinLido__factory {
    return super.connect(signer) as MixinLido__factory;
  }
  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): MixinLidoInterface {
    return new utils.Interface(_abi) as MixinLidoInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): MixinLido {
    return new Contract(address, _abi, signerOrProvider) as MixinLido;
  }
}
