import React from 'react';
// import { Counter } from './features/counter/Counter';
import { Dex } from './features/dex/Dex';

function App() {
  return (
    <div>
      {/* <Counter />
      <hr></hr> */}
      <Dex />
    </div>
  );
}

export default App;
