/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import { Provider, TransactionRequest } from "@ethersproject/providers";
import type {
  MixinMooniswap,
  MixinMooniswapInterface,
} from "../MixinMooniswap";

const _abi = [
  {
    inputs: [
      {
        internalType: "contract IEtherTokenV06",
        name: "weth",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
];

const _bytecode =
  "0x60a060405234801561001057600080fd5b5060405161018d38038061018d83398181016040528101906100329190610108565b6100647f4be84aaf2f57cd41e9315ddc4b24a63ad63419c1898cab07bb614993e50781a060001b61010560201b60201c565b6100967f2b046451f017d75964a920f1ce0f55435f4c7e0cfaf63443e4ea85850d43b5e060001b61010560201b60201c565b6100c87ff8a50b1a2c03dd446ddc33e9f970518ea59bae36d4133b29a0eeeec539c2a4cc60001b61010560201b60201c565b8073ffffffffffffffffffffffffffffffffffffffff1660808173ffffffffffffffffffffffffffffffffffffffff1660601b8152505050610138565b50565b600060208284031215610119578081fd5b815160018060a01b038116811461012e578182fd5b8091505092915050565b60805160601c603f61014e60003950603f6000f3fe6080604052600080fdfea2646970667358221220a36645cff09605a2922ee3554f965ec5fa1d69bcc992e5f154ecc0f38fbba1f964736f6c634300060c0033";

export class MixinMooniswap__factory extends ContractFactory {
  constructor(
    ...args: [signer: Signer] | ConstructorParameters<typeof ContractFactory>
  ) {
    if (args.length === 1) {
      super(_abi, _bytecode, args[0]);
    } else {
      super(...args);
    }
  }

  deploy(
    weth: string,
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<MixinMooniswap> {
    return super.deploy(weth, overrides || {}) as Promise<MixinMooniswap>;
  }
  getDeployTransaction(
    weth: string,
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(weth, overrides || {});
  }
  attach(address: string): MixinMooniswap {
    return super.attach(address) as MixinMooniswap;
  }
  connect(signer: Signer): MixinMooniswap__factory {
    return super.connect(signer) as MixinMooniswap__factory;
  }
  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): MixinMooniswapInterface {
    return new utils.Interface(_abi) as MixinMooniswapInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): MixinMooniswap {
    return new Contract(address, _abi, signerOrProvider) as MixinMooniswap;
  }
}
