/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type {
  IBatchFillNativeOrdersFeature,
  IBatchFillNativeOrdersFeatureInterface,
} from "../IBatchFillNativeOrdersFeature";

const _abi = [
  {
    inputs: [
      {
        components: [
          {
            internalType: "contract IERC20TokenV06",
            name: "makerToken",
            type: "address",
          },
          {
            internalType: "contract IERC20TokenV06",
            name: "takerToken",
            type: "address",
          },
          {
            internalType: "uint128",
            name: "makerAmount",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "takerAmount",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "takerTokenFeeAmount",
            type: "uint128",
          },
          {
            internalType: "address",
            name: "maker",
            type: "address",
          },
          {
            internalType: "address",
            name: "taker",
            type: "address",
          },
          {
            internalType: "address",
            name: "sender",
            type: "address",
          },
          {
            internalType: "address",
            name: "feeRecipient",
            type: "address",
          },
          {
            internalType: "bytes32",
            name: "pool",
            type: "bytes32",
          },
          {
            internalType: "uint64",
            name: "expiry",
            type: "uint64",
          },
          {
            internalType: "uint256",
            name: "salt",
            type: "uint256",
          },
        ],
        internalType: "struct LibNativeOrder.LimitOrder[]",
        name: "orders",
        type: "tuple[]",
      },
      {
        components: [
          {
            internalType: "enum LibSignature.SignatureType",
            name: "signatureType",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "v",
            type: "uint8",
          },
          {
            internalType: "bytes32",
            name: "r",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "s",
            type: "bytes32",
          },
        ],
        internalType: "struct LibSignature.Signature[]",
        name: "signatures",
        type: "tuple[]",
      },
      {
        internalType: "uint128[]",
        name: "takerTokenFillAmounts",
        type: "uint128[]",
      },
      {
        internalType: "bool",
        name: "revertIfIncomplete",
        type: "bool",
      },
    ],
    name: "batchFillLimitOrders",
    outputs: [
      {
        internalType: "uint128[]",
        name: "takerTokenFilledAmounts",
        type: "uint128[]",
      },
      {
        internalType: "uint128[]",
        name: "makerTokenFilledAmounts",
        type: "uint128[]",
      },
    ],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "contract IERC20TokenV06",
            name: "makerToken",
            type: "address",
          },
          {
            internalType: "contract IERC20TokenV06",
            name: "takerToken",
            type: "address",
          },
          {
            internalType: "uint128",
            name: "makerAmount",
            type: "uint128",
          },
          {
            internalType: "uint128",
            name: "takerAmount",
            type: "uint128",
          },
          {
            internalType: "address",
            name: "maker",
            type: "address",
          },
          {
            internalType: "address",
            name: "taker",
            type: "address",
          },
          {
            internalType: "address",
            name: "txOrigin",
            type: "address",
          },
          {
            internalType: "bytes32",
            name: "pool",
            type: "bytes32",
          },
          {
            internalType: "uint64",
            name: "expiry",
            type: "uint64",
          },
          {
            internalType: "uint256",
            name: "salt",
            type: "uint256",
          },
        ],
        internalType: "struct LibNativeOrder.RfqOrder[]",
        name: "orders",
        type: "tuple[]",
      },
      {
        components: [
          {
            internalType: "enum LibSignature.SignatureType",
            name: "signatureType",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "v",
            type: "uint8",
          },
          {
            internalType: "bytes32",
            name: "r",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "s",
            type: "bytes32",
          },
        ],
        internalType: "struct LibSignature.Signature[]",
        name: "signatures",
        type: "tuple[]",
      },
      {
        internalType: "uint128[]",
        name: "takerTokenFillAmounts",
        type: "uint128[]",
      },
      {
        internalType: "bool",
        name: "revertIfIncomplete",
        type: "bool",
      },
    ],
    name: "batchFillRfqOrders",
    outputs: [
      {
        internalType: "uint128[]",
        name: "takerTokenFilledAmounts",
        type: "uint128[]",
      },
      {
        internalType: "uint128[]",
        name: "makerTokenFilledAmounts",
        type: "uint128[]",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export class IBatchFillNativeOrdersFeature__factory {
  static readonly abi = _abi;
  static createInterface(): IBatchFillNativeOrdersFeatureInterface {
    return new utils.Interface(_abi) as IBatchFillNativeOrdersFeatureInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IBatchFillNativeOrdersFeature {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as IBatchFillNativeOrdersFeature;
  }
}
