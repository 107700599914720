import { ethers } from "ethers";


export function getFEEAddress(_network?: ethers.providers.Network) {
    const FEE_ADDRESS = "0x93140929fBf04c361E10F13bc913F1AF15DfD11A";
    return FEE_ADDRESS;
}

export function getERC20Address(_network?: ethers.providers.Network) {
    const MOCKERC20 = "0x1182A7D780f0C0Aa41286D0219fE74Ebec084791";
    return MOCKERC20;
}

// export function getERC721Address(_network?: ethers.providers.Network) {
//     const MOCKERC721 = "0x55e8F3B6ae8B3e5fBB64764D292603aef270dcbd";
//     return MOCKERC721;
// }

// export function getERC1155Address(_network?: ethers.providers.Network) {
//     const MOCKERC1155 = "0x9c5F6E475528A8b6D9B19B2D537CcC0df2B3cffE";
//     return MOCKERC1155;
// }

export function getWETHAddress(_network?: ethers.providers.Network) {
    const WETH = "0xc778417e063141139fce010982780140aa0cd5ab";
    return WETH;
}

export function getZEROEXAddress(_network?: ethers.providers.Network) {
    const ZEROEX = "0xb446f2fdDF9F2d7C0ad2a5c061F8A62223976eCC";
    return ZEROEX;
}

