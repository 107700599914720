/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type {
  AbstractBridgeAdapter,
  AbstractBridgeAdapterInterface,
} from "../AbstractBridgeAdapter";

const _abi = [
  {
    inputs: [
      {
        internalType: "uint256",
        name: "expectedChainId",
        type: "uint256",
      },
      {
        internalType: "string",
        name: "expectedChainName",
        type: "string",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes32",
        name: "source",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "contract IERC20TokenV06",
        name: "inputToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "contract IERC20TokenV06",
        name: "outputToken",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "inputTokenAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "outputTokenAmount",
        type: "uint256",
      },
    ],
    name: "BridgeFill",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "source",
        type: "bytes32",
      },
    ],
    name: "isSupportedSource",
    outputs: [
      {
        internalType: "bool",
        name: "isSupported",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "bytes32",
            name: "source",
            type: "bytes32",
          },
          {
            internalType: "uint256",
            name: "takerTokenAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "makerTokenAmount",
            type: "uint256",
          },
          {
            internalType: "bytes",
            name: "bridgeData",
            type: "bytes",
          },
        ],
        internalType: "struct IBridgeAdapter.BridgeOrder",
        name: "order",
        type: "tuple",
      },
      {
        internalType: "contract IERC20TokenV06",
        name: "sellToken",
        type: "address",
      },
      {
        internalType: "contract IERC20TokenV06",
        name: "buyToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "sellAmount",
        type: "uint256",
      },
    ],
    name: "trade",
    outputs: [
      {
        internalType: "uint256",
        name: "boughtAmount",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export class AbstractBridgeAdapter__factory {
  static readonly abi = _abi;
  static createInterface(): AbstractBridgeAdapterInterface {
    return new utils.Interface(_abi) as AbstractBridgeAdapterInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): AbstractBridgeAdapter {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as AbstractBridgeAdapter;
  }
}
