/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type {
  IMultiplexFeature,
  IMultiplexFeatureInterface,
} from "../IMultiplexFeature";

const _abi = [
  {
    inputs: [
      {
        internalType: "contract IERC20TokenV06",
        name: "outputToken",
        type: "address",
      },
      {
        components: [
          {
            internalType: "enum IMultiplexFeature.MultiplexSubcall",
            name: "id",
            type: "uint8",
          },
          {
            internalType: "uint256",
            name: "sellAmount",
            type: "uint256",
          },
          {
            internalType: "bytes",
            name: "data",
            type: "bytes",
          },
        ],
        internalType: "struct IMultiplexFeature.BatchSellSubcall[]",
        name: "calls",
        type: "tuple[]",
      },
      {
        internalType: "uint256",
        name: "minBuyAmount",
        type: "uint256",
      },
    ],
    name: "multiplexBatchSellEthForToken",
    outputs: [
      {
        internalType: "uint256",
        name: "boughtAmount",
        type: "uint256",
      },
    ],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract IERC20TokenV06",
        name: "inputToken",
        type: "address",
      },
      {
        components: [
          {
            internalType: "enum IMultiplexFeature.MultiplexSubcall",
            name: "id",
            type: "uint8",
          },
          {
            internalType: "uint256",
            name: "sellAmount",
            type: "uint256",
          },
          {
            internalType: "bytes",
            name: "data",
            type: "bytes",
          },
        ],
        internalType: "struct IMultiplexFeature.BatchSellSubcall[]",
        name: "calls",
        type: "tuple[]",
      },
      {
        internalType: "uint256",
        name: "sellAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "minBuyAmount",
        type: "uint256",
      },
    ],
    name: "multiplexBatchSellTokenForEth",
    outputs: [
      {
        internalType: "uint256",
        name: "boughtAmount",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract IERC20TokenV06",
        name: "inputToken",
        type: "address",
      },
      {
        internalType: "contract IERC20TokenV06",
        name: "outputToken",
        type: "address",
      },
      {
        components: [
          {
            internalType: "enum IMultiplexFeature.MultiplexSubcall",
            name: "id",
            type: "uint8",
          },
          {
            internalType: "uint256",
            name: "sellAmount",
            type: "uint256",
          },
          {
            internalType: "bytes",
            name: "data",
            type: "bytes",
          },
        ],
        internalType: "struct IMultiplexFeature.BatchSellSubcall[]",
        name: "calls",
        type: "tuple[]",
      },
      {
        internalType: "uint256",
        name: "sellAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "minBuyAmount",
        type: "uint256",
      },
    ],
    name: "multiplexBatchSellTokenForToken",
    outputs: [
      {
        internalType: "uint256",
        name: "boughtAmount",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address[]",
        name: "tokens",
        type: "address[]",
      },
      {
        components: [
          {
            internalType: "enum IMultiplexFeature.MultiplexSubcall",
            name: "id",
            type: "uint8",
          },
          {
            internalType: "bytes",
            name: "data",
            type: "bytes",
          },
        ],
        internalType: "struct IMultiplexFeature.MultiHopSellSubcall[]",
        name: "calls",
        type: "tuple[]",
      },
      {
        internalType: "uint256",
        name: "minBuyAmount",
        type: "uint256",
      },
    ],
    name: "multiplexMultiHopSellEthForToken",
    outputs: [
      {
        internalType: "uint256",
        name: "boughtAmount",
        type: "uint256",
      },
    ],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address[]",
        name: "tokens",
        type: "address[]",
      },
      {
        components: [
          {
            internalType: "enum IMultiplexFeature.MultiplexSubcall",
            name: "id",
            type: "uint8",
          },
          {
            internalType: "bytes",
            name: "data",
            type: "bytes",
          },
        ],
        internalType: "struct IMultiplexFeature.MultiHopSellSubcall[]",
        name: "calls",
        type: "tuple[]",
      },
      {
        internalType: "uint256",
        name: "sellAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "minBuyAmount",
        type: "uint256",
      },
    ],
    name: "multiplexMultiHopSellTokenForEth",
    outputs: [
      {
        internalType: "uint256",
        name: "boughtAmount",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address[]",
        name: "tokens",
        type: "address[]",
      },
      {
        components: [
          {
            internalType: "enum IMultiplexFeature.MultiplexSubcall",
            name: "id",
            type: "uint8",
          },
          {
            internalType: "bytes",
            name: "data",
            type: "bytes",
          },
        ],
        internalType: "struct IMultiplexFeature.MultiHopSellSubcall[]",
        name: "calls",
        type: "tuple[]",
      },
      {
        internalType: "uint256",
        name: "sellAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "minBuyAmount",
        type: "uint256",
      },
    ],
    name: "multiplexMultiHopSellTokenForToken",
    outputs: [
      {
        internalType: "uint256",
        name: "boughtAmount",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export class IMultiplexFeature__factory {
  static readonly abi = _abi;
  static createInterface(): IMultiplexFeatureInterface {
    return new utils.Interface(_abi) as IMultiplexFeatureInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IMultiplexFeature {
    return new Contract(address, _abi, signerOrProvider) as IMultiplexFeature;
  }
}
