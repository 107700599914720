import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { ethers } from "ethers";
import { NULL_ADDRESS } from "./utils";

export interface CounterState {
  provider: ethers.providers.Web3Provider;
  ethereum: any;
  currentBlockNumber: number;
  accountAddress: string;
  network?: ethers.providers.Network;
}

const initialState: CounterState = {
  provider: new ethers.providers.Web3Provider(function (_method: string, _params?: Array<any>): Promise<any> {
    return Promise.any([]);
  }, "any"),
  ethereum: null,
  currentBlockNumber: 0,
  accountAddress: NULL_ADDRESS,
};

export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    setProvider: (state, action: PayloadAction<any>) => {
      state.ethereum = action.payload;
      state.provider = new ethers.providers.Web3Provider(action.payload, "any");
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBlockNumber.fulfilled, (state, action) => {
        state.currentBlockNumber = action.payload;
      })
      .addCase(getAccount.fulfilled, (state, action) => {
        state.accountAddress = action.payload;
      })
      .addCase(getNetwork.fulfilled, (state, action) => {
        state.network = action.payload;
      });
  },
});

export const getBlockNumber = createAsyncThunk(
  'dex/getBlockNumber',
  async (_arg, { getState }: any) => {
    const provider = selectProvider(getState());
    return await provider?.getBlockNumber() || 0;
  }
);

export const getNetwork = createAsyncThunk(
  'dex/getNetwork',
  async (_arg, { getState }: any) => {
    const provider = selectProvider(getState());
    return await provider?.getNetwork();
  }
);

export const getAccount = createAsyncThunk(
  'dex/getAccount',
  async (_arg, { getState }: any) => {
    const ethereum = selectEthereum(getState());
    const accounts = await ethereum?.request({ method: 'eth_requestAccounts' }) || [NULL_ADDRESS];
    return accounts[0];
  }
);

export const { setProvider } = counterSlice.actions;
export const selectCurrentBlockNumber = (state: RootState) => state.dex.currentBlockNumber;
export const selectProvider = (state: RootState) => state.dex.provider;
export const selectAccountAddress = (state: RootState) => state.dex.accountAddress;
export const selectEthereum = (state: RootState) => state.dex.ethereum;
export const selectNetwork = (state: RootState) => state.dex.network;
export default counterSlice.reducer;
