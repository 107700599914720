/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type {
  LibNFTOrdersRichErrors1,
  LibNFTOrdersRichErrors1Interface,
} from "../LibNFTOrdersRichErrors1";

const _abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "token1",
        type: "address",
      },
      {
        internalType: "address",
        name: "token2",
        type: "address",
      },
    ],
    name: "ERC1155TokenMismatchError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token1",
        type: "address",
      },
      {
        internalType: "address",
        name: "token2",
        type: "address",
      },
    ],
    name: "ERC20TokenMismatchError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token1",
        type: "address",
      },
      {
        internalType: "address",
        name: "token2",
        type: "address",
      },
    ],
    name: "ERC721TokenMismatchError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint128",
        name: "remainingOrderAmount",
        type: "uint128",
      },
      {
        internalType: "uint128",
        name: "fillAmount",
        type: "uint128",
      },
    ],
    name: "ExceedsRemainingOrderAmount",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "ethAvailable",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "orderAmount",
        type: "uint256",
      },
    ],
    name: "InsufficientEthError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "maker",
        type: "address",
      },
      {
        internalType: "address",
        name: "signer",
        type: "address",
      },
    ],
    name: "InvalidSignerError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "sellOrderAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "buyOrderAmount",
        type: "uint256",
      },
    ],
    name: "NegativeSpreadError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "sender",
        type: "address",
      },
      {
        internalType: "address",
        name: "taker",
        type: "address",
      },
    ],
    name: "OnlyTakerError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "maker",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "nonce",
        type: "uint256",
      },
      {
        internalType: "uint8",
        name: "orderStatus",
        type: "uint8",
      },
    ],
    name: "OrderNotFillableError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "ethSpent",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "ethAvailable",
        type: "uint256",
      },
    ],
    name: "OverspentEthError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "propertyValidator",
        type: "address",
      },
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      {
        internalType: "bytes",
        name: "propertyData",
        type: "bytes",
      },
      {
        internalType: "bytes",
        name: "errorData",
        type: "bytes",
      },
    ],
    name: "PropertyValidationFailedError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "sellOrderFees",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "spread",
        type: "uint256",
      },
    ],
    name: "SellOrderFeesExceedSpreadError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "orderTokenId",
        type: "uint256",
      },
    ],
    name: "TokenIdMismatchError",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
];

export class LibNFTOrdersRichErrors1__factory {
  static readonly abi = _abi;
  static createInterface(): LibNFTOrdersRichErrors1Interface {
    return new utils.Interface(_abi) as LibNFTOrdersRichErrors1Interface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): LibNFTOrdersRichErrors1 {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as LibNFTOrdersRichErrors1;
  }
}
